import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {ALL} from '_logics/LFCConst';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CloudDistanceSearchValue} from '../types';
import {SearchTypeRadioBtn} from './SearchTypeRadioBtn';

interface CloudDistanceSearchPanelProps {
  searchValue: CloudDistanceSearchValue;
  setSearchValue: React.Dispatch<React.SetStateAction<CloudDistanceSearchValue>>;
  handleSearch: () => void;
  resetSearch: () => void;
  changeSearchType: () => void;
  setScreenLock: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (event: any) => void;
  autoOnChange: (relayDatas: any) => void;
}

export const CloudDistanceSearchPanel = ({
  searchValue,
  setSearchValue,
  handleSearch,
  resetSearch,
  changeSearchType,
  setScreenLock,
  handleChange,
  autoOnChange
}: CloudDistanceSearchPanelProps) => {
  const [openSavedCondition, setOpenSavedCondition] = useState<boolean>(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const [isAutoCompleteReset, setIsAutoCompleteReset] = useState<boolean>(false);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel:
          searchValue.select_date_from === ''
            ? ''
            : dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間From'),
        colWidth: 200
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel:
          searchValue.select_date_to === ''
            ? ''
            : dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間To'),
        colWidth: 200
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : '',
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: !isEmpty(searchValue.deviceid) ? searchValue.deviceid.join(',') : '',
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge === 0 ? 'OK' : 'NG',
        colName: t('総合判定'),
        colWidth: 100
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel:
          searchValue.searchType === 0
            ? t('検査ごと')
            : searchValue.searchType === 1
              ? t('1時間ごと')
              : searchValue.searchType === 2
                ? t('1日ごと')
                : searchValue.searchType === 3
                  ? t('1ヶ月ごと')
                  : '',
        colName: t('集計単位'),
        colWidth: 100
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: ConditionParamer[]) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setIsAutoCompleteReset(true);
  };

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);

  useEffect(() => {
    setScreenLock(true);
    Promise.allSettled([
      //機種
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42009,
        parameters: {},
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: {work: string}) => {
          return {label: item['work']};
        });
        setWork(tmp);
      }),
      //検査装置
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42010,
        parameters: {},
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: {deviceid: string}) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
    ]).finally(() => {
      setScreenLock(false);
    });
  }, []);

  return (
    <>
      <LFCFormRowGroup>
        <LFCDatetimePicker
          name={t('select_date_from')}
          label={t('対象期間From')}
          value={searchValue.select_date_from}
          required
          onChange={handleChange}
        />
        <LFCDatetimePicker
          name={t('select_date_to')}
          label={t('対象期間To')}
          value={searchValue.select_date_to}
          required
          onChange={handleChange}
        />
        <LFCAutocomplete
          name={'work'}
          label={t('機種')}
          id={'work'}
          value={multiSelectData(work, searchValue.work)}
          onChange={autoOnChange}
          onReset={isAutoCompleteReset}
          doneReset={setIsAutoCompleteReset}
          multiple={true}
          selectItem={work}
        />
        <LFCAutocomplete
          name={'deviceid'}
          label={t('検査装置')}
          id={'deviceid'}
          value={multiSelectData(deviceid, searchValue.deviceid)}
          onChange={autoOnChange}
          multiple={true}
          onReset={isAutoCompleteReset}
          doneReset={setIsAutoCompleteReset}
          selectItem={deviceid}
        />
        <LFCSelectFormJudge
          name={'judge'}
          value={searchValue.judge}
          onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
        />{' '}
      </LFCFormRowGroup>
      <LFCFormRowGroup>
        <SearchTypeRadioBtn
          value={searchValue.searchType}
          onChange={val => {
            setSearchValue({...searchValue, searchType: val});
            changeSearchType();
          }}
        />

        <LFCButton color="primary" onClick={handleSearch}>
          {t('検索')}
        </LFCButton>
        <LFCButton onClick={resetSearch}>{t('リセット')}</LFCButton>
      </LFCFormRowGroup>
      <LFCFormRowGroup></LFCFormRowGroup>
    </>
  );
};
