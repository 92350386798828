import {COLOR_NG, COLOR_OK} from '_logics/LFCConst';
import {useEffect, useState} from 'react';

import {Chip, Grid, Stack, Switch, Typography} from '@mui/material';

import MapIconViewer, {formatIconId, MapIconProps} from './MapIconViewer';

const SELECT_COLOR = 'rgb(245, 124, 0)';

export type SelectedMapIconProps = {
  imageNo: number;
  map: string;
};

type ImageResultsProps = {
  imgWidth: number;
  imageRootPath: string;
  workBases: any[]; //ワーク画像
  mapPointResults: any[]; //Map位置&検査結果情報
  selected: SelectedMapIconProps | null;
  onSelected: (imageNo: number, map: string) => void;
};

const ImageResults = (props: ImageResultsProps) => {
  const [workBases, setWorkBases] = useState<any[]>(props.workBases);
  const [mapPointResults, setMapPoints] = useState<any[]>(props.mapPointResults);
  const [ts, setTs] = useState(new Date().getTime()); //ワーク画像のキャッシュ防止用
  const [checkedShowNgOnly, setCheckedShowNgOnly] = useState(false); //NGのみ表示フラグ
  const [checkedShowMap, setCheckedShowMap] = useState(true); //部位名表示フラグ
  const [innerSelectedIconId, setInnerSelectedIconId] = useState<string | null>(null); //(image_no)-(map)

  /***
   * NGのみ表示チェックボックス変更
   */
  const onChangeShowNgOnly = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckedShowNgOnly(checked);
  };

  /***
   * 部位名表示チェックボックス変更
   */
  const onChangeShowMap = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckedShowMap(checked);
  };

  /***
   * 部位クリックイベント
   */
  const onMapClick = (iconId: string, imageNo: number, map: string) => {
    setInnerSelectedIconId(iconId);
    props.onSelected(imageNo, map);
  };

  useEffect(() => {
    setWorkBases(props.workBases);
    setTs(new Date().getTime());
  }, [props.workBases]);

  useEffect(() => {
    setMapPoints(props.mapPointResults);
  }, [props.mapPointResults]);

  /***
   * 部位クリックイベント
   */
  useEffect(() => {
    if (props.selected === null) {
      return;
    }
    const iconid = formatIconId(props.selected.imageNo, props.selected.map);
    if (iconid === innerSelectedIconId) {
      return;
    }
    setInnerSelectedIconId(iconid);
    props.onSelected(props.selected.imageNo, props.selected.map);
  }, [props.selected]);

  return mapPointResults.length === 0 ? null : (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="row" flexWrap={'wrap'}>
          <Stack direction="row" alignItems="center" mr={1} mb={1}>
            <Chip size="small" label="NGのみ" variant="outlined" />
            <Switch checked={checkedShowNgOnly} onChange={onChangeShowNgOnly} />
          </Stack>
          <Stack direction="row" alignItems="center" mr={1} mb={1}>
            <Chip size="small" label="部位名" variant="outlined" />
            <Switch checked={checkedShowMap} onChange={onChangeShowMap} />
          </Stack>
        </Stack>
      </Grid>
      {/* ワーク画像登録されているデータの表示 */}
      {workBases.map((mapBase: any, index: number) => {
        const image_no = mapBase['image_no'];

        //該当する画像Noのデータ分だけで処理
        const resIcons = mapPointResults
          .filter((cr: any) => cr['image_no'] === image_no)
          .map((r: any) => {
            return {
              id: `${formatIconId(image_no, r['map'])}`,
              map: r['map'],
              color:
                r['judge'] === 0
                  ? checkedShowNgOnly
                    ? ''
                    : 'rgba(0, 255, 0, 1)'
                  : 'rgba(255, 0, 0, 1)',
              position: {relativeX: r['relative_x'], relativeY: r['relative_y']},
              selected: innerSelectedIconId === formatIconId(image_no, r['map']),
              isShowLabel: checkedShowNgOnly && r['judge'] === 0 ? false : checkedShowMap,
              isAttention: r['judge'] !== 0
            } as MapIconProps;
          })
          .filter((icon: MapIconProps | null): icon is MapIconProps => icon !== null);

        return (
          <Grid item xs={12} sx={{mb: 1}} key={`work-image-grid-${image_no}-${index}`}>
            <MapIconViewer
              containerWidth={props.imgWidth}
              containerHeight={props.imgWidth * mapBase['aspect_ratio']}
              imgPath={`${props.imageRootPath}${mapBase['image_path']}?${ts}`}
              icons={resIcons}
              iconRatio={mapBase['icon_ratio']}
              onIconClick={onMapClick}
            ></MapIconViewer>
          </Grid>
        );
      })}
      {/* ワーク画像登録されてないデータの表示 */}
      {[99].map((image_no: number, index: number) => {
        //画像Noだけで絞った結果
        const fltMapPoints = mapPointResults.filter((cr: any) => cr['image_no'] === image_no);

        return (
          <Grid item xs={12} sx={{mb: 1}} key={`work-text-grid-${image_no}-${index}`}>
            <Stack>
              {/* TODO: */}
              {/* <Alert
                key={'alert_warn'}
                variant="outlined"
                severity="warning"
                sx={{mb: 2}}
              >{`この機種はワーク画像または部位の位置が登録されていません`}</Alert> */}
              <Stack direction="row" flexWrap={'wrap'}>
                {fltMapPoints.map((r: any, index: number) => {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={`stack-${r['map']}-${index}`}
                      mr={1}
                      mb={1}
                    >
                      <Chip size="small" label="部位判定" />
                      <Stack
                        direction="row"
                        onClick={() => {
                          onMapClick(formatIconId(99, r['map']), 99, r['map']);
                        }}
                        sx={{
                          border:
                            innerSelectedIconId === formatIconId(image_no, r['map'])
                              ? `3px solid ${SELECT_COLOR}`
                              : '',
                          cursor: 'pointer'
                        }}
                      >
                        <Typography ml={1} variant="h6" noWrap>
                          {r['map'] || '-'}
                        </Typography>
                        <Typography
                          ml={1}
                          px={2}
                          variant="h6"
                          noWrap
                          sx={{
                            backgroundColor: r['judge'] === 0 ? COLOR_OK : COLOR_NG,
                            animation:
                              r['judge'] === 0
                                ? ''
                                : 'map-pulse 0.75s infinite, map-flash 0.75s infinite'
                          }}
                        >
                          {`${r['judge'] === 0 ? 'OK' : 'NG'}`}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ImageResults;
