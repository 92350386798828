import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';

import {Divider} from '@mui/material';

import {CloudDistanceDig} from './components/cloud-distance-dig';
import {CloudDistanceMainPanel} from './components/cloud-distance-main-panel';
import {CloudDistanceSearchPanel} from './components/CloudDistanceSearchPanel';
import {useCloudDistance} from './hooks/useCloudDistance';

export const CloudDistancePage = () => {
  const {
    startProcess,
    screenLock,
    t,
    searchValue,
    tmpSearchValue,
    setTmpSearchValue,
    handleChange,
    autoOnChange,
    handleSearch,
    resetSearch,
    open,
    openData,
    handleClose,
    mapData,
    selectedMaps,
    setSelectedMaps,
    graphClick,
    graphBaseDatas,
    listData,
    changeSearchType,
    setScreenLock
  } = useCloudDistance();
  const title = 'menu.tab_name.L-Qジョブ傾向分析';
  const description = 'menu.page_name.Cloud Distance';

  return (
    <GenericTemplate title={`${t(title)}：${t(description)}`}>
      <ProgressBar startProcess={startProcess} screenLock={screenLock} />
      <CloudDistanceSearchPanel
        searchValue={tmpSearchValue}
        setSearchValue={setTmpSearchValue}
        handleChange={handleChange}
        autoOnChange={autoOnChange}
        handleSearch={handleSearch}
        resetSearch={resetSearch}
        changeSearchType={changeSearchType}
        setScreenLock={setScreenLock}
      />
      <Divider sx={{margin: '0.5em 0'}} />
      <CloudDistanceMainPanel
        mapData={mapData}
        selectedMaps={selectedMaps}
        setSelectedMaps={setSelectedMaps}
        graphClick={graphClick}
        graphBaseDatas={graphBaseDatas}
        listData={listData}
        t={t}
        searchValue={searchValue}
      />
      <CloudDistanceDig
        pageName={`${t('menu.page_name.Cloud Distance')}: ${t('詳細')}`}
        open={open}
        onClose={handleClose}
        datas={openData}
        searchValue={searchValue}
      />
    </GenericTemplate>
  );
};
