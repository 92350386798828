import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {COLOR_NG, COLOR_OK, FORMAT_HHmmSS, FORMAT_YYYYMMDD} from '_logics/LFCConst';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import {useEffect, useRef, useState} from 'react';

export interface DataItem {
  uuid: string;
  work: string;
  serial: string;
  start_time: string;
  end_time: string;
  total_judge: number;
  deviceid: string;
}

interface Props {
  ds: DataItem[];
  selectedIndex: number;
  zoom: number[]; //0 start,1 end
  onClick: (uuid: string) => void;
}

export const LFCChartsGantt = (props: Props) => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState(['categoryA']);
  const [zoom, setZoom] = useState<number[]>(props.zoom);

  const keepZoom = useRef<number[]>([0, 100]);

  const [selectedData, setSelectedData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  // Generate mock data

  const renderItem = (params: any, api: any) => {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height = api.size([0, 1])[1] * 0.9;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
      }
    );
    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: api.style({
          // fill: api.visual('color'),
          stroke: params.dataIndex === selectedIndex ? 'rgb(255, 193, 7)' : api.visual('color'), // 選択されたデータの色を変更
          opacity: params.dataIndex === selectedIndex ? 1 : 0.5,
          lineWidth: params.dataIndex === selectedIndex ? 3 : 0
        })
      }
    );
  };

  const handleDataSelect = (params: any) => {
    setSelectedData(params.value[6]); //uuid
    setSelectedIndex(params.dataIndex); // 選択されたデータのインデックスを設定

    setZoom(keepZoom.current);
    props.onClick(params.value[6]);
  };

  const handleDataZoom = (params: any) => {
    keepZoom.current = [params.batch[0].start, params.batch[0].end];
  };

  useEffect(() => {
    const dd: any = [];
    props.ds.forEach((item: DataItem) => {
      dd.push({
        name: `${item.serial}(${item.work})`,
        value: [
          0,
          dayjs(item.start_time).toDate(),
          dayjs(item.end_time).toDate(),
          dayjs(item.end_time).diff(dayjs(item.start_time)),
          item.work,
          item.serial,
          item.uuid
        ],
        itemStyle: {
          color: item.total_judge === 0 ? COLOR_OK : COLOR_NG
        }
      });
    });

    setData(dd);
  }, [props.ds]);

  useEffect(() => {
    setZoom(props.zoom);
    keepZoom.current = props.zoom;
  }, [props.zoom]);

  useEffect(() => {
    if (props.selectedIndex === selectedIndex) return;

    setSelectedIndex(props.selectedIndex);
    setZoom(keepZoom.current);
  }, [props.selectedIndex]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        height={'100%'}
        option={{
          tooltip: {
            axisPointer: {
              type: 'none'
            },
            formatter: function (params: any) {
              return `${params.marker}${params.name}<br>${dayjs(params.value[1]).format(
                `${FORMAT_YYYYMMDD}  ${FORMAT_HHmmSS}`
              )}<br>${dayjs.duration(params.value[3]).format('mm:ss')} <br>`;
            }
          },
          title: {
            top: 25,
            text: dayjs(props.ds[0].start_time).format(FORMAT_YYYYMMDD)
          },
          dataZoom: [
            // {
            //   type: 'slider',
            //   filterMode: 'weakFilter',
            //   showDataShadow: false,
            //   top: 100,
            //   labelFormatter: '',
            //   start: zoomStart, // 初期値の開始位置
            //   end: zoomEnd // 初期値の終了位置
            // },
            {
              type: 'inside',
              filterMode: 'weakFilter',
              start: zoom[0], // 初期値の開始位置
              end: zoom[1] // 初期値の終了位置
            }
          ],
          grid: {
            top: 0,
            left: 0,
            right: 0,
            height: 75
          },
          xAxis: {
            type: 'time',
            boundaryGap: false
          },
          yAxis: {
            data: categories,
            axisLabel: '',
            axisLine: {
              show: false
            }
          },
          series: [
            {
              type: 'custom',
              renderItem: renderItem,
              itemStyle: {
                opacity: 1
              },
              encode: {
                x: [1, 2],
                y: 0
              },
              data: data
            }
          ]
        }}
        onEvents={{
          click: handleDataSelect,
          dataZoom: handleDataZoom
        }}
      />
    </div>
  );
};
