import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {CloudDistanceDigGraphBaseData} from 'pages/lq-job/cloud-distance/components/cloud-distance-dig/types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {blue, lightBlue, pink} from '@mui/material/colors';

import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';

/**
 * 引数
 */
interface Props {
  title: string;
  source: CloudDistanceDigGraphBaseData;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  isShowDetailAnalysis?: boolean;
  onClickDetailAnalysis?: any;
  zoomStatus?: boolean;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  averageUpperDistance?: any;
  medianUpperDistance?: any;
  averagePlus3SD?: any;
  averageMinus3SD?: any;
  searchType?: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ8 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);
  const [legendSelected, setLegendSelected] = useState({
    箱ひげ図: true,
    最大値: true,
    群平均: false,
    群中央: false,
    上限: true,
    下限: true,
    中央: false,
    平均: false,
    '平均+3σ': false,
    '平均-3σ': false
  });
  const [tooltipContent, setTooltipContent] = useState('');
  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp_upper_distance: any = [];
    let tmp_lower_distance: any = [];
    let tmpOutPut: any = [];
    tmpOutPut[0] = [];
    tmpOutPut[1] = [];
    tmpOutPut[2] = [];

    if (!isEmpty(props.source.xlist)) {
      props.source.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp_upper_distance.push(props.source.data_upper_distance[index]);
              tmp_lower_distance.push(props.source.data_lower_distance[index]);
            }
          });
        } else {
          tmp_upper_distance.push([]);
          tmp_lower_distance.push([]);
        }
      });

      tmp_upper_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[0].push(CalcQuartile(a));
        } else {
          tmpOutPut[0].push([]);
        }
      });
      tmp_lower_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[1].push(CalcQuartile(a));
        } else {
          tmpOutPut[1].push([]);
        }
      });
    } else {
      props.source.xlist.find((b: string, index: number) => {
        tmpOutPut[0].push(CalcQuartile([props.source.data_upper_distance[index]]));
        tmpOutPut[1].push(CalcQuartile([props.source.data_lower_distance[index]]));
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportFields, setExportFields] = useState({});
  const [exportData, setExportData] = useState<any>([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    if (props.searchType === 0) {
      //検査ごと
      setExportFields({
        シリアルナンバー: t('シリアルナンバー'),
        検査日時: t('検査日時'),
        検査部位: t('検査部位'),
        最小値: t('最小値'),
        上限: t('上限'),
        下限: t('下限')
      });

      const _exportData = props?.source?.xlist.map((xval: string, i: number) => {
        return {
          シリアルナンバー: props.source.detail[i].serial || props.source.detail[i].uuid,
          検査日時: fmtYYYYMMDDHHmmss(props.source.detail[i].lq_time),
          検査部位: props.source.map,
          最小値: props.source.data_lower_distance[i],
          上限: props.source.data_upper_limits[i],
          下限: props.source.data_lower_limits[i]
        };
      });
      setExportData(_exportData);
    } else {
      setExportFields({
        検査日時: t('検査日時'),
        検査部位: t('検査部位'),
        最大値_最大: t('最大値　最大'),
        最大値_Q3: t('最大値　Q3'),
        最大値_中央: t('最大値　中央'),
        最大値_Q1: t('最大値　Q1'),
        最大値_最小: t('最大値　最小'),
        上限: t('上限'),
        下限: t('下限')
      });

      if (boxData.length === 0) return;

      const _exportData = props?.source?.xlist
        .map((xval: string, i: number) => {
          if (!Array.isArray(boxData[0][i])) return null;
          if ((boxData[1][i] as any).length === 0) return null;

          return {
            検査日時: fmtYYYYMMDDHHmmss(xval),
            検査部位: props.source.map,
            最小値_最大: boxData[1][i][4],
            最小値_Q3: boxData[1][i][3],
            最小値_中央: boxData[1][i][2],
            最小値_Q1: boxData[1][i][1],
            最小値_最小: boxData[1][i][0],
            上限: props.source.limits_max,
            下限: props.source.limits_min
          };
        })
        .filter((item: any) => item !== null); // nullの行を除外

      setExportData(_exportData);
    }
  }, [boxData, props.searchType]);

  const calcDataAvgMel = (source: any) => {
    return source.data_lower_distance.map((sublist: any[]) => {
      if (!Array.isArray(sublist) || sublist.length === 0) {
        return null;
      }
      const sum = sublist.reduce((acc, val) => acc + val, 0);
      return sum / sublist.length;
    });
  };
  const calcDataMiddleMel = (source: any) => {
    return source.data_lower_distance.map((sublist: any[]) => {
      if (!Array.isArray(sublist) || sublist.length === 0) {
        return null;
      }
      const sortedList = sublist.slice().sort((a, b) => a - b);
      const midIndex = Math.floor(sortedList.length / 2);

      if (sortedList.length % 2 === 0) {
        return (sortedList[midIndex - 1] + sortedList[midIndex]) / 2;
      } else {
        return sortedList[midIndex];
      }
    });
  };

  const legendChange = (data: any) => {
    if (data && data.selected) {
      const selectedData = data.selected;
      let newTooltipContent = '';

      if (selectedData['平均-3σ']) {
        newTooltipContent +=
          '<br/>' + t('平均-3σ') + ':' + Number(props.averageMinus3SD).toFixed(2);
      }

      if (selectedData['平均+3σ']) {
        newTooltipContent += '<br/>' + t('平均+3σ') + ':' + Number(props.averagePlus3SD).toFixed(2);
      }

      if (selectedData['平均']) {
        newTooltipContent +=
          '<br/>' + t('平均') + ':' + Number(props.averageUpperDistance).toFixed(2);
      }

      if (selectedData['中央']) {
        newTooltipContent +=
          '<br/>' + t('中央') + ':' + Number(props.medianUpperDistance).toFixed(2);
      }
      setTooltipContent(newTooltipContent);
      setLegendSelected(selectedData);
    }
  };

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: '90%',
            textStyle: {
              fontSize: 10
            },
            data: [
              {name: t('箱ひげ図'), icon: 'circle'},
              {name: t('最小値'), icon: 'circle'},
              {name: t('群平均'), icon: 'circle'},
              {name: t('群中央'), icon: 'circle'},
              {name: t('上限'), icon: 'circle'},
              {name: t('下限'), icon: 'circle'},
              {name: t('中央'), icon: 'circle'},
              {name: t('平均'), icon: 'circle'},
              {name: t('平均+3σ'), icon: 'circle'},
              {name: t('平均-3σ'), icon: 'circle'}
            ],
            selected: legendSelected
          },
          tooltip: {
            trigger: 'axis', // 軸に沿ってtooltipを表示するために'axis'を使用
            formatter: function (params: any) {
              if (!Array.isArray(params) || params.length === 0) {
                return 'データなし';
              }

              const getValue = (param: any, index: number) => {
                if (param && param.value !== undefined) {
                  if (Array.isArray(param.value)) {
                    return param.value[index] !== undefined
                      ? Number(param.value[index]).toFixed(2)
                      : 'データなし';
                  }
                  return isNaN(param.value) ? 'データなし' : Number(param.value).toFixed(2);
                }
                return 'データなし';
              };

              if (props.searchType === 0) {
                return (
                  t('連番') +
                  ': ' +
                  (params[0]?.name || 'データなし') +
                  '<br/>' +
                  t('シリアルナンバー') +
                  ': ' +
                  (props.source.detail[params[0].dataIndex].serial ||
                    props.source.detail[params[0].dataIndex].uuid ||
                    'データなし') +
                  '<br/>' +
                  t('検査日時') +
                  ': ' +
                  (props.source.detail[params[0].dataIndex].lq_time
                    ? dayjs(props.source.detail[params[0].dataIndex].lq_time).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    : 'データなし') +
                  '<br/>' +
                  t('最小値') +
                  ': ' +
                  getValue(params[0], 0) +
                  '<br/>' +
                  t('上限') +
                  ': ' +
                  getValue(params[1], 0) +
                  '<br/>' +
                  t('下限') +
                  ': ' +
                  getValue(params[2], 0)
                );
              }

              return (
                (params[0]?.name || 'データなし') +
                '<br/>' +
                t('最大') +
                ': ' +
                getValue(params[0], 5) +
                '<br/>' +
                'Q3：' +
                getValue(params[0], 4) +
                '<br/>' +
                t('中央') +
                ' (or Q2)：' +
                getValue(params[0], 3) +
                '<br/>' +
                'Q1：' +
                getValue(params[0], 2) +
                '<br/>' +
                t('最小') +
                ': ' +
                getValue(params[0], 1) +
                '<br/>' +
                '----<br/>' +
                t('上限') +
                ': ' +
                getValue(params[1], 0) +
                '<br/>' +
                t('下限') +
                ': ' +
                getValue(params[2], 0) +
                tooltipContent
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '75',
            right: '50',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            splitLine: {
              show: true
            },
            axisLabel: {
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: (value: {min: number; max: number}) => {
              let val = value.min;
              if (legendSelected['平均-3σ']) {
                val = Math.min(val, props.averageMinus3SD);
              }
              return Math.floor(val * 10) / 10;
            },
            max: (value: {min: number; max: number}) => {
              let val = value.max;
              if (legendSelected['平均+3σ']) {
                val = Math.max(val, props.averagePlus3SD);
              }
              return Math.ceil(val * 10) / 10;
            }
          },
          series:
            props.searchType === 0
              ? [
                  {
                    name: t('最小値'),
                    type: 'line',
                    data: props.source.data_lower_distance,
                    color: blue[500]
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: pink[500],
                    data: props.source.data_upper_limits,
                    symbolSize: 0,
                    lineStyle: {
                      type: 'dotted'
                    },
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: lightBlue[500],
                    data: props.source.data_lower_limits,
                    symbolSize: 0,
                    lineStyle: {
                      type: 'dotted'
                    },
                    connectNulls: true // null を無視して線をつなぐ
                  }
                ]
              : [
                  {
                    name: t('箱ひげ図'),
                    type: 'boxplot',
                    data: boxData[1],
                    color: blue[500]
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: pink[500],
                    symbolSize: 0,
                    lineStyle: {
                      type: 'dotted'
                    },
                    data: props.source.data_upper_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: lightBlue[500],
                    symbolSize: 0,
                    lineStyle: {
                      type: 'dotted'
                    },
                    data: props.source.data_lower_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('中央'),
                    type: 'line',
                    color: colorPallet[2],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.medianUpperDistance,
                          name: t('中央')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[2],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        color: colorPallet[2],
                        fontSize: 10,
                        formatter: t('中央'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均'),
                    type: 'line',
                    color: colorPallet[4],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averageUpperDistance,
                          name: t('平均')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[4],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        color: colorPallet[4],
                        fontSize: 10,
                        formatter: t('平均'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均+3σ'),
                    type: 'line',
                    color: colorPallet[5],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averagePlus3SD,
                          name: t('平均+3σ')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[5],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        color: colorPallet[5],
                        fontSize: 10,
                        formatter: t('平均+3σ'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均-3σ'),
                    type: 'line',
                    color: colorPallet[6],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averageMinus3SD,
                          name: t('平均-3σ')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[6],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        color: colorPallet[6],
                        fontSize: 10,
                        formatter: t('平均-3σ'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('群平均'),
                    type: 'line',
                    legendHoverLink: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    data: props.source ? calcDataAvgMel(props.source) : false,
                    lineStyle: {
                      width: 1
                    },
                    color: colorPallet[1],
                    connectNulls: true // データがない部分を飛ばして線を繋ぐ
                  },
                  {
                    name: t('群中央'),
                    type: 'line',
                    legendHoverLink: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    data: props.source ? calcDataMiddleMel(props.source) : [],
                    lineStyle: {
                      width: 1
                    },
                    color: colorPallet[3],
                    connectNulls: true // データがない部分を飛ばして線を繋ぐ
                  }
                ]
        }}
        exportData={exportData}
        exportFields={exportFields}
        exportFilename={`CloudDistance_${t('最小値推移')}`}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          },
          legendselectchanged: (params: any) => {
            legendChange(params);
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
        isShowDetailAnalysis={props.isShowDetailAnalysis ?? false}
        onClickDetailAnalysis={props.onClickDetailAnalysis ?? undefined}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxXYZ8;
