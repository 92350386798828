import 'App.css';

import AuthUserProvider from '_contexts/AuthUserProvider';
import MenuTabProvider from '_contexts/MenuTabProvider';
import MySettingsProvider, {useMySettings} from '_contexts/MySettingsProvider';
import SavedConditionProvider from '_contexts/SavedConditionProvider';
import SystemSettingsProvider from '_contexts/SystemSettingsProvider';
import AIChatPage from 'pages/_system/aichat/AIChatPage';
import LoginPage from 'pages/_system/login/LoginPage';
import PasswordResetPage from 'pages/_system/password-reset/PasswordResetPage';
import {AlertSettingsPage} from 'pages/abnormality-monitoring/alert-settings';
import MasterPage from 'pages/admin/common-master/CommonMasterPage';
import CompanyMaintenance from 'pages/admin/company-maintenance/CompanyMaintenance';
import CompanyMaster from 'pages/admin/company-master/CompanyMaster';
import DbCheckPage from 'pages/admin/dbcheck/DbCheckPage';
import EasyNaviPage from 'pages/admin/easy-navi/EasyNaviPage';
import HeartBeatPage from 'pages/admin/heartbeat/HeartBeatPage';
import PriceCheckPage from 'pages/admin/price-check/PriceCheckPage';
import RemoteAccessMaintenance from 'pages/admin/remote-access-maintenance/RemoteAccessMaintenance';
import SignUpPage from 'pages/admin/signup/SignUpPage';
import TabsSettingAdminPage from 'pages/admin/tabs-setting-admin/TabsSettingAdminPage';
import UsageAnalysisPage from 'pages/admin/usage-analysis/UsageAnalysisPage';
import UserMaster from 'pages/admin/user-master/UserMaster';
import MapImagesPage from 'pages/common/map-images/MapImagesPage';
import WorkImagesPage from 'pages/common/work-images/WorkImagesPage';
import {ResultsPage} from 'pages/development/results';
import {ResultsHistoryPage} from 'pages/development/results-history';
import {SearchByBarcodePage} from 'pages/development/search-by-barcode';
import IndexPage from 'pages/g7_demo/IndexPage';
import HomePage from 'pages/HomePage';
import FailureAnalysisPage from 'pages/l-qualify/failureanalysis/FailureAnalysisPage';
import InspectionProgressMonitorPage from 'pages/l-qualify/inspection-progress-monitor/InspectionProgressMonitorPage';
import InspectionResultsPage from 'pages/l-qualify/inspectionresults/InspectionResultsPage';
import SearchByDayPage from 'pages/l-qualify/search/SearchByDayPage';
import SearchByMonthPage from 'pages/l-qualify/search/SearchByMonthPage';
import SearchByYearPage from 'pages/l-qualify/search/SearchByYearPage';
import StartupSupportPage from 'pages/l-qualify/startup-support/StartupSupportPage';
import M_FailureAnalysis from 'pages/l-robot/failure-analysis/FailureAnalysisPage';
import M_MapEntry from 'pages/l-robot/map-entry/MapEntryPage';
import M_OnsiteMonitor from 'pages/l-robot/onsite-monitor/OnsiteMonitorPage';
import M_SearchByDay from 'pages/l-robot/search/SearchByDayPage';
import M_SearchByMonth from 'pages/l-robot/search/SearchByMonthPage';
import M_SearchByYear from 'pages/l-robot/search/SearchByYearPage';
import M_Search from 'pages/l-robot/search/SearchPage';
import M_SystemLog from 'pages/l-robot/system-log/SystemLogPage';
import BumpCheckPage from 'pages/lq-job/BumpCheckPage';
import {CloudDistancePage} from 'pages/lq-job/cloud-distance';
import HoleCompareDiameterPage from 'pages/lq-job/HoleCompareDiameterPage';
import HoleComparePositionPage from 'pages/lq-job/HoleComparePositionPage';
import LengthComparePage from 'pages/lq-job/LengthComparePage';
import ResultComparePage from 'pages/lq-job/ResultComparePage';
import SurfaceComparePage from 'pages/lq-job/SurfaceComparePage';
import FittingPage from 'pages/lr-job/FittingPage';
import PathGeneratePage from 'pages/lr-job/PathGeneratePage';
import WeldingPathPage from 'pages/lr-job/WeldingPathPage';
import CompareAnalysisPage from 'pages/persons/CompareAnalysisPage';
import CompareAnalysisWithFacilityPage from 'pages/persons/CompareAnalysisWithFacilityPage';
import DailyReportWorkerPage from 'pages/persons/DailyReportWorkerPage';
import DailyReportWorkerWithFacilityPage from 'pages/persons/DailyReportWorkerWithFacilityPage';
import MonthlyReportWorkerPage from 'pages/persons/MonthlyReportWorkerPage';
import MonthlyReportWorkerWithFacilityPage from 'pages/persons/MonthlyReportWorkerWithFacilityPage';
import WorkTimeMonitorPage from 'pages/persons/WorkTimeMonitorPage';
import WorkTimeMonitorWithFacilityPage from 'pages/persons/WorkTimeMonitorWithFacilityPage';
import WorkTimeTrendAnalysisPage from 'pages/persons/WorkTimeTrendAnalysisPage';
import WorkTimeTrendAnalysisWithFacilityPage from 'pages/persons/WorkTimeTrendAnalysisWithFacilityPage';
import ChipExchange from 'pages/poc/chip-exchange/ChipExchange';
import DefectiveProcedure from 'pages/poc/defective-procedure/DefectiveProcedure';
import MasterMaintenance from 'pages/poc/master-maintenance/MasterMaintenance';
import MonthlyResults from 'pages/poc/monthly-results/MonthlyResults';
import OnsiteMonitor from 'pages/poc/onsite-monitor/OnsiteMonitor';
import ProductivityAnalysis from 'pages/poc/productivity-analysis/ProductivityAnalysis';
import WorkerPerformance from 'pages/poc/worker-performance/WorkerPerformance';
import YearlyResults from 'pages/poc/yearly-results/YearlyResults';
import Yield from 'pages/poc/yield/Yield';
import ResultWorkImagePage from 'pages/prototype/result-work-images/ResultWorkImagePage';
import TestPage from 'pages/TestPage';
import UserMaintenance from 'pages/user_settings/user-maintenance/UserMaintenance ';
import DailyReportPage from 'pages/welding/daily-report/DailyReportPage';
import MonitorPage from 'pages/welding/monitor/MonitorPage';
import MonthlyReportPage from 'pages/welding/monthly-report/MonthlyReportPage';
import OnSiteObserverPage from 'pages/welding/on-site-observer/OnSiteObserverPage';
import ProcessingConditionFinderPage from 'pages/welding/processing-condition-finder/ProcessingConditionFinderPage';
import ProductionStatusPage from 'pages/welding/production-status/ProductionStatusPage';
import TraceAbilitySearchPage from 'pages/welding/trace-ability-search/TraceAbilitySearchPage';
import WeldingDemoPage from 'pages/welding_demo/index';
import {WeldingShowPage} from 'pages/weldingShow';
import React from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation
} from 'react-router-dom';

/**
 * AuthRoute(認証TOP)
 * @param param0
 * @returns
 */
const AuthRoute: React.FC<RouteProps> = ({...props}) => {
  const isAuthenticated = Object.keys(useMySettings()).length;
  const location = useLocation<{from: string}>();
  const {from} = location.state || {from: {pathname: '/'}};

  if (0 < isAuthenticated) {
    // login認証OK & の場合、URLで指定したページへ
    // @ts-ignore
    return <Redirect to={from} />;
  } else {
    // 未loginの場合、指定ページ(login)へ
    // @ts-ignore
    return <Route {...props} />;
  }
};

/**
 * PublicRoute(認証不要)
 * @param param0
 * @returns
 */
const PublicRoute: React.FC<RouteProps> = ({...props}) => {
  // @ts-ignore
  return <Route {...props} />;
};

/**
 * PrivateRoute(要認証)
 * @param param0
 * @returns
 */
const PrivateRoute: React.FC<RouteProps> = ({...props}) => {
  const isAuthenticated = Object.keys(useMySettings()).length;

  // アプリのアップデートチェック
  navigator.serviceWorker.getRegistration().then(registration => {
    registration?.update();
  });

  if (0 < isAuthenticated) {
    // Page to Pageの場合、指定ページへ
    // @ts-ignore
    return <Route {...props} />;
  } else {
    // Reload or DirectLinkの場合、再認証(現在の認証情報を破棄してログインページへ)
    // @ts-ignore
    return <Redirect to={{pathname: '/login', state: {from: props.location?.pathname}}} />;
  }
};

/**
 * App
 * @returns
 */
const App: React.VFC = () => {
  return (
    <AuthUserProvider>
      <MySettingsProvider>
        {/* ↑↑認証情報ここまで */}
        <SystemSettingsProvider>
          <MenuTabProvider>
            <SavedConditionProvider>
              <div className="sw-dialog"></div>
              <Router basename="/">
                <Switch>
                  <PrivateRoute path="/" component={HomePage} exact />
                  {/* 共通ヘッダ */}
                  <PrivateRoute path="/aichat" component={AIChatPage} exact />
                  {/* system */}
                  <AuthRoute path="/login" component={LoginPage} exact />
                  <PublicRoute path="/password-reset" component={PasswordResetPage} exact />
                  {/* LW用管理 */}
                  <PrivateRoute
                    path="/admin/tabs-setting-admin"
                    component={TabsSettingAdminPage}
                    exact
                  />
                  <PrivateRoute path="/admin/signup" component={SignUpPage} exact />
                  <PrivateRoute path="/admin/user-master" component={UserMaster} exact />
                  <PrivateRoute path="/admin/company-master" component={CompanyMaster} exact />
                  <PrivateRoute
                    path="/admin/company-maintenance"
                    component={CompanyMaintenance}
                    exact
                  />
                  <PrivateRoute
                    path="/admin/remote-access-maintenance"
                    component={RemoteAccessMaintenance}
                    exact
                  />
                  <PrivateRoute path="/admin/dbcheck" component={DbCheckPage} exact />
                  <PrivateRoute path="/admin/common-master" component={MasterPage} exact />
                  <PrivateRoute path="/admin/easy-navi" component={EasyNaviPage} exact />
                  <PrivateRoute path="/admin/price-check" component={PriceCheckPage} exact />

                  {/* 共通系機能 */}
                  <PrivateRoute path="/common/map-images" component={MapImagesPage} exact />
                  <PrivateRoute path="/common/work-images" component={WorkImagesPage} exact />

                  {/* エンドユーザ用管理 */}
                  <PrivateRoute
                    path="/user_settings/user-maintenance"
                    component={UserMaintenance}
                    exact
                  />

                  {/* l-qualify */}
                  {/* <PrivateRoute path="/l-qualify/andon" component={AndonPage} exact /> */}
                  <PrivateRoute
                    path="/l-qualify/inspection-progress-monitor"
                    component={InspectionProgressMonitorPage}
                    exact
                  />
                  <PrivateRoute
                    path="/l-qualify/failureanalysis"
                    component={FailureAnalysisPage}
                    exact
                  />
                  <PrivateRoute
                    path="/l-qualify/inspectionresults"
                    component={InspectionResultsPage}
                    exact
                  />
                  {/* <PrivateRoute path="/l-qualify/manual" component={ManualPage} exact /> */}
                  {/* <PrivateRoute path="/l-qualify/search" component={SearchPage} exact /> */}
                  <PrivateRoute path="/l-qualify/search-by-day" component={SearchByDayPage} exact />
                  <PrivateRoute
                    path="/l-qualify/search-by-month"
                    component={SearchByMonthPage}
                    exact
                  />
                  <PrivateRoute
                    path="/l-qualify/search-by-year"
                    component={SearchByYearPage}
                    exact
                  />
                  {/* <PrivateRoute path="/l-qualify/support" component={SupportPage} exact /> */}

                  {/* l-robot */}
                  <PrivateRoute path="/l-robot/onsite-monitor" component={M_OnsiteMonitor} exact />
                  <PrivateRoute
                    path="/l-robot/failure-analysis"
                    component={M_FailureAnalysis}
                    exact
                  />
                  <PrivateRoute path="/l-robot/search" component={M_Search} exact />
                  {/* <PrivateRoute path="/l-robot/job-trend-analysis" component={M_JobTrendAnalysis} exact /> */}
                  <PrivateRoute path="/l-robot/search-by-day" component={M_SearchByDay} exact />
                  <PrivateRoute path="/l-robot/search-by-month" component={M_SearchByMonth} exact />
                  <PrivateRoute path="/l-robot/search-by-year" component={M_SearchByYear} exact />
                  <PrivateRoute path="/l-robot/map-entry" component={M_MapEntry} exact />
                  <PrivateRoute path="/l-robot/system-log" component={M_SystemLog} exact />

                  {/* 生産性分析(平岡POC用) */}
                  <PrivateRoute path="/poc/onsite-monitor" component={OnsiteMonitor} exact />
                  <PrivateRoute path="/poc/yearly-results" component={YearlyResults} exact />
                  <PrivateRoute path="/poc/monthly-results" component={MonthlyResults} exact />
                  <PrivateRoute
                    path="/poc/worker-performance"
                    component={WorkerPerformance}
                    exact
                  />
                  <PrivateRoute
                    path="/poc/productivity-analysis"
                    component={ProductivityAnalysis}
                    exact
                  />
                  <PrivateRoute
                    path="/poc/defective-procedure"
                    component={DefectiveProcedure}
                    exact
                  />
                  <PrivateRoute path="/poc/chip-exchange" component={ChipExchange} exact />
                  <PrivateRoute path="/poc/yield" component={Yield} exact />
                  <PrivateRoute
                    path="/poc/master-maintenance"
                    component={MasterMaintenance}
                    exact
                  />

                  {/* サポート */}
                  {/* <PrivateRoute path="/support/manual-upload" component={ManuelUploadPage} exact />
                  <PrivateRoute path="/support/support-common-master" component={SupportCommonMasterPage} exact />
                  <PrivateRoute path="/support/support-inquiry" component={SupportInquiryPage} exact />
                  <PrivateRoute path="/support/support-monitor" component={SupportMonitorPage} exact /> */}
                  <PrivateRoute
                    path="/l-qualify/startup-support"
                    component={StartupSupportPage}
                    exact
                  />
                  <PrivateRoute path="/admin/heartbeat" component={HeartBeatPage} exact />
                  <PrivateRoute path="/admin/usage-analysis" component={UsageAnalysisPage} exact />

                  {/* 板金溶接 */}
                  <PrivateRoute path="/welding/monitor" component={MonitorPage} exact />
                  <PrivateRoute path="/welding/daily-report" component={DailyReportPage} exact />
                  <PrivateRoute
                    path="/welding/monthly-report"
                    component={MonthlyReportPage}
                    exact
                  />
                  <PrivateRoute
                    path="/welding/production-status"
                    component={ProductionStatusPage}
                    exact
                  />
                  <PrivateRoute
                    path="/welding/on-site-observer"
                    component={OnSiteObserverPage}
                    exact
                  />
                  <PrivateRoute
                    path="/welding/processing-condition-finder"
                    component={ProcessingConditionFinderPage}
                    exact
                  />
                  <PrivateRoute
                    path="/welding/trace-ability-search"
                    component={TraceAbilitySearchPage}
                    exact
                  />

                  {/* 人ログ */}
                  <PrivateRoute
                    path="/persons/work-time-monitor"
                    component={WorkTimeMonitorPage}
                    exact
                  />
                  <PrivateRoute
                    path="/persons/work-time-trend-analysis"
                    component={WorkTimeTrendAnalysisPage}
                    exact
                  />
                  <PrivateRoute
                    path="/persons/compare-analysis"
                    component={CompareAnalysisPage}
                    exact
                  />
                  <PrivateRoute
                    path="/persons/daily-report-worker"
                    component={DailyReportWorkerPage}
                    exact
                  />
                  <PrivateRoute
                    path="/persons/monthly-report-worker"
                    component={MonthlyReportWorkerPage}
                    exact
                  />

                  {/* MM分析 */}
                  <PrivateRoute
                    path="/mm/work-time-monitor-with-facility"
                    component={WorkTimeMonitorWithFacilityPage}
                    exact
                  />
                  <PrivateRoute
                    path="/mm/daily-report-worker-with-facility"
                    component={DailyReportWorkerWithFacilityPage}
                    exact
                  />
                  <PrivateRoute
                    path="/mm/work-time-trend-analysis-with-facility"
                    component={WorkTimeTrendAnalysisWithFacilityPage}
                    exact
                  />
                  <PrivateRoute
                    path="/mm/compare-analysis-with-facility"
                    component={CompareAnalysisWithFacilityPage}
                    exact
                  />
                  <PrivateRoute
                    path="/mm/monthly-report-worker-with-facility"
                    component={MonthlyReportWorkerWithFacilityPage}
                    exact
                  />

                  {/* L-Qジョブ傾向分析 */}
                  <PrivateRoute path="/lq-job/bump-check" component={BumpCheckPage} exact />
                  <PrivateRoute path="/lq-job/cloud-distance" component={CloudDistancePage} exact />
                  <PrivateRoute path="/lq-job/length-compare" component={LengthComparePage} exact />
                  <PrivateRoute
                    path="/lq-job/hole-compare-diameter"
                    component={HoleCompareDiameterPage}
                    exact
                  />
                  <PrivateRoute
                    path="/lq-job/hole-compare-position"
                    component={HoleComparePositionPage}
                    exact
                  />
                  <PrivateRoute
                    path="/lq-job/surface-compare"
                    component={SurfaceComparePage}
                    exact
                  />
                  <PrivateRoute path="/lq-job/result-compare" component={ResultComparePage} exact />

                  {/* L-Rジョブ傾向分析 */}
                  <PrivateRoute path="/lr-job/fitting" component={FittingPage} exact />
                  <PrivateRoute path="/lr-job/path-generate" component={PathGeneratePage} exact />
                  <PrivateRoute path="/lr-job/welding-path" component={WeldingPathPage} exact />

                  {/* 展示会デモ */}
                  <PrivateRoute path="/g7-demo" component={IndexPage} exact />
                  <PrivateRoute path="/welding-show" component={WeldingShowPage} exact />

                  {/* Weldingデモ */}
                  <PrivateRoute path="/welding-demo" component={WeldingDemoPage} exact />

                  {/* Development */}
                  <PrivateRoute path="/development" component={ResultsPage} exact />
                  <PrivateRoute path="/development-history" component={ResultsHistoryPage} exact />
                  <PrivateRoute
                    path="/development/search-by-barcode"
                    component={SearchByBarcodePage}
                    exact
                  />

                  {/* 異常監視 */}
                  <PrivateRoute
                    path="/abnormality-monitoring/alert-settings"
                    component={AlertSettingsPage}
                    exact
                  />

                  {/* その他 */}
                  <PrivateRoute
                    path="/prototype/result-work-images"
                    component={ResultWorkImagePage}
                    exact
                  />
                  <PrivateRoute path="/test" component={TestPage} exact />
                </Switch>
              </Router>
            </SavedConditionProvider>
          </MenuTabProvider>
        </SystemSettingsProvider>
      </MySettingsProvider>
    </AuthUserProvider>
  );
};

export default App;
