import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {handleInputChange2} from '_logics/LFCUtil';
import {TFunction} from 'i18next';

import {Box} from '@mui/material';

import {SearchTypeRadioBtn} from '../../SearchTypeRadioBtn';
import {CloudDistanceDigSearchValue} from '../types';

interface CloudDistanceDigSearchPanelProps {
  t: TFunction<'translation', undefined, 'translation'>;
  searchValue: CloudDistanceDigSearchValue;
  setSearchValue: (value: React.SetStateAction<CloudDistanceDigSearchValue>) => void;
  handleChange: (event: any) => void;
  autoOnChange: (relayDatas: any) => void;
  work: {label: string}[];
  map: {label: string}[];
  deviceid: {label: string}[];
  autoCompleteReset: boolean;
  setAutoCompleteReset: React.Dispatch<React.SetStateAction<boolean>>;
  doSearch: any;
  restSearch: any;
  setDetailListOpen: any;
  changeSearchType: any;
}

export const CloudDistanceDigSearchPanel = ({
  t,
  searchValue,
  setSearchValue,
  handleChange,
  autoOnChange,
  work,
  map,
  deviceid,
  autoCompleteReset,
  setAutoCompleteReset,
  doSearch,
  restSearch,
  setDetailListOpen,
  changeSearchType
}: CloudDistanceDigSearchPanelProps) => {
  // useEffect(() => {
  //   setAutoCompleteReset(true);
  // }, [work, deviceid, map]);

  return (
    <Box>
      <form>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCAutocomplete
            name={t('map')}
            label={t('検査部位')}
            id={'map'}
            size="small"
            value={multiSelectData(map, searchValue.map)}
            onChange={autoOnChange}
            multiple={false}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={map}
            style={{minWidth: '250px'}}
          />
          <LFCAutocomplete
            name={'deviceid'}
            label={t('検査装置')}
            id={'deviceid'}
            size="small"
            value={multiSelectData(deviceid, searchValue.deviceid)}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={deviceid}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <SearchTypeRadioBtn
            value={searchValue.searchType}
            onChange={val => {
              setSearchValue({...searchValue, searchType: val});
              changeSearchType();
            }}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
        </LFCFormRowGroup>
      </form>
    </Box>
  );
};
