import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION_NEW, PAGEID_LQ_JOBANALYSIS} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import axios, {CancelTokenSource} from 'axios';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  CloudDistanceGraphBaseData,
  CloudDistanceGraphSelectData,
  CloudDistanceListData,
  CloudDistanceSearchValue
} from '../types';

export const useCloudDistance = () => {
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const [screenLock, setScreenLock] = useState(false);
  const {t} = useTranslation();
  const getDefaultCondition = useGetDefaultCondition();
  const [listData, setListData] = useState<CloudDistanceListData[]>([]);
  const [mapData, setMapData] = useState<string[]>([]);
  const [selectedMaps, setSelectedMaps] = useState<string[]>([]);
  const [graphBaseDatas, setGraphBaseDatas] = useState<CloudDistanceGraphBaseData[]>([]);
  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState<CloudDistanceGraphSelectData>({mapName: ''});
  const cancelSource = useRef<CancelTokenSource | null>(null);

  //入力中条件
  const [tmpSearchValue, setTmpSearchValue] = useState<CloudDistanceSearchValue>(
    getDefaultCondition(PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION_NEW) ?? {
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    }
  );
  //確定条件
  const [searchValue, setSearchValue] = useState<CloudDistanceSearchValue>({
    select_date_from: '',
    select_date_to: '',
    work: [],
    deviceid: [],
    judge: 0,
    searchType: 0
  });

  /***
   * 画面ロック状態切替
   */
  useEffect(() => {
    setStartProcess(screenLock);
  }, [screenLock]);

  // 入力値変更イベント
  const handleChange = useCallback((event: any) => {
    setTmpSearchValue(prev => ({...prev, [event.target.name]: event.target.value}));
  }, []);

  const autoOnChange = useCallback((relayDatas: any) => {
    setTmpSearchValue(prev => ({...prev, [relayDatas.name]: relayDatas.data}));
  }, []);

  /***
   * X軸生成処理
   */
  const dateCalc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        let start_month_1day = new Date(from_day.setDate(1));
        let end_month_1day = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [searchValue.select_date_from, searchValue.select_date_to, searchValue.searchType]);

  /**
   * 集計単位変更イベント
   */
  const changeSearchType = useCallback(() => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');
    setScreenLock(false);

    // setMapData([]);
    // setSelectedMaps([]);
  }, [cancelSource]);

  /**
   * 検索処理
   */
  const handleSearch = useCallback(async () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    //検索条件FIX
    setSearchValue(tmpSearchValue);

    setMapData([]);
    setSelectedMaps([]);

    setScreenLock(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // MAP一覧取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 30004,
        parameters: {
          select_date_from: tmpSearchValue.select_date_from,
          select_date_to: tmpSearchValue.select_date_to,
          work: !isEmpty(tmpSearchValue.work) ? '{' + tmpSearchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(searchValue.deviceid)
            ? '{' + tmpSearchValue.deviceid.join(',') + '}'
            : null,
          judge: tmpSearchValue.judge === 9 ? null : tmpSearchValue.judge
        },
        name: 'id-30004',
        cancelToken: cancelSource.current.token,
        t
      }).then(data => {
        const maps = data.map((item: {map: string}[]) => item.map);
        setMapData(maps);

        if (maps.length > 0) {
          //最初の１件目を選択状態にする
          setSelectedMaps([maps[0]]);
        }
      })
    ]).finally(() => {
      setScreenLock(false);
    });
  }, [cancelSource, tmpSearchValue, searchValue, enqueueSnackbar, t]);

  /**
   * 検索リセット処理
   */
  const resetSearch = useCallback(() => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');
    setScreenLock(false);

    setTmpSearchValue({
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    });
    setMapData([]);
    setGraphBaseDatas([]);
    setSelectedMaps([]);
  }, [cancelSource]);

  /**
   * グラフクリック処理
   */
  const graphClick = useCallback((datas: CloudDistanceGraphSelectData) => {
    setOpenData(datas);
    setOpen(true);
  }, []);

  /**
   * ダイアログクローズ処理
   */
  const handleClose = useCallback(() => {
    setOpenData({mapName: ''});
    setOpen(false);
  }, []);

  /**
   * MAP選択切替
   */
  useEffect(() => {
    if (selectedMaps.length === 0) {
      setListData([]);
      return;
    }

    setScreenLock(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 30007,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(searchValue.deviceid)
            ? '{' + searchValue.deviceid.join(',') + '}'
            : null,
          judge: searchValue.judge === 9 ? null : searchValue.judge,
          map: !isEmpty(selectedMaps) ? '{' + selectedMaps.join(',') + '}' : null
        },
        name: 'id-30003',
        cancelToken: cancelSource.current.token,
        t
      }).then(ds => setListData(ds))
    ]).finally(() => {
      setScreenLock(false);
    });
  }, [selectedMaps]);

  /**
   * チャートデータ生成処理
   */
  useEffect(() => {
    const resultList: CloudDistanceGraphBaseData[] = [];

    selectedMaps.forEach((map_string: string) => {
      const tmp_row: CloudDistanceGraphBaseData = {
        data_upper_distance: [],
        data_lower_distance: [],
        data_upper_limits: [],
        data_lower_limits: [],
        upper_distance_min: 0,
        upper_distance_max: 0,
        lower_distance_min: 0,
        lower_distance_max: 0,
        limits_min: 0,
        limits_max: 0,
        hasMultipleLimit: false,
        xlist: [],
        detail: [], // listDataの行情報を持つ
        map: ''
      };

      tmp_row.map = map_string;

      const ldata = listData.filter((a: any) => a.map === map_string);
      if (ldata.length === 0) {
        resultList.push(tmp_row);
        return;
      }

      if (searchValue.searchType === 0) {
        //検査毎
        ldata.forEach((r: any) => {
          tmp_row.xlist.push(r.idx);
          tmp_row.detail.push(r);
          //検査毎の場合だけ数値が直に入ってる
          tmp_row.data_upper_distance.push(Number(r.upper_distance));
          tmp_row.data_lower_distance.push(Number(r.lower_distance));
          tmp_row.data_upper_limits.push(Number(r.upper_limit));
          tmp_row.data_lower_limits.push(Number(r.lower_limit));
        });
        tmp_row.upper_distance_min = parseFloat(
          Math.min(...(tmp_row.data_upper_distance as Array<number>)).toFixed(2)
        );
        tmp_row.upper_distance_max = parseFloat(
          Math.max(...(tmp_row.data_upper_distance as Array<number>)).toFixed(2)
        );
        tmp_row.lower_distance_min = parseFloat(
          Math.min(...(tmp_row.data_lower_distance as Array<number>)).toFixed(2)
        );
        tmp_row.lower_distance_max = parseFloat(
          Math.max(...(tmp_row.data_lower_distance as Array<number>)).toFixed(2)
        );
        tmp_row.limits_min = parseFloat(
          Math.min(...(tmp_row.data_lower_limits as Array<number>)).toFixed(2)
        );
        tmp_row.limits_max = parseFloat(
          Math.max(...(tmp_row.data_upper_limits as Array<number>)).toFixed(2)
        );
      } else {
        dateCalc().forEach((lq_time_string: string) => {
          let tmp_upper_distance: number[] = [];
          let tmp_lower_distance: number[] = [];
          let tmp_upper_limits: number[] = [];
          let tmp_lower_limits: number[] = [];
          let tval = 0;
          ldata.forEach((r: any) => {
            if (
              (searchValue.searchType === 1 && r.year_month_day_hour === lq_time_string) ||
              (searchValue.searchType === 2 && r.year_month_day === lq_time_string) ||
              (searchValue.searchType === 3 && r.year_month === lq_time_string)
            ) {
              tmp_upper_distance.push(Number(r.upper_distance));
              tmp_lower_distance.push(Number(r.lower_distance));
              tmp_upper_limits.push(Number(r.upper_limit));
              tmp_lower_limits.push(Number(r.lower_limit));
            }
          });
          tmp_row.xlist.push(lq_time_string);
          tmp_row.detail.push([]);
          tmp_row.data_upper_distance.push(tmp_upper_distance);
          tmp_row.data_lower_distance.push(tmp_lower_distance);
          tmp_row.data_upper_limits.push(tmp_upper_limits);
          tmp_row.data_lower_limits.push(tmp_lower_limits);

          tval = parseFloat(Math.min(...tmp_upper_distance).toFixed(2));
          if (tval < tmp_row.upper_distance_min) {
            tmp_row.upper_distance_min = tval;
          }
          tval = parseFloat(Math.max(...tmp_upper_distance).toFixed(2));
          if (tmp_row.upper_distance_max < tval) {
            tmp_row.upper_distance_max = tval;
          }
          tval = parseFloat(Math.min(...tmp_lower_distance).toFixed(2));
          if (tval < tmp_row.lower_distance_min) {
            tmp_row.lower_distance_min = tval;
          }
          tval = parseFloat(Math.max(...tmp_lower_distance).toFixed(2));
          if (tmp_row.lower_distance_max < tval) {
            tmp_row.lower_distance_max = tval;
          }
          tval = parseFloat(Math.min(...tmp_lower_limits).toFixed(2));
          if (tval < tmp_row.limits_min) {
            tmp_row.limits_min = tval;
          }
          tval = parseFloat(Math.max(...tmp_upper_limits).toFixed(2));
          if (tmp_row.limits_max < tval) {
            tmp_row.limits_max = tval;
          }
        });
      }
      resultList.push(tmp_row);
    });
    setGraphBaseDatas(resultList);
  }, [listData]);

  return {
    startProcess,
    screenLock,
    t,
    mapData,
    selectedMaps,
    setSelectedMaps,
    searchValue,
    tmpSearchValue,
    setTmpSearchValue,
    handleChange,
    autoOnChange,
    handleSearch,
    resetSearch,
    graphClick,
    open,
    openData,
    handleClose,
    graphBaseDatas,
    listData,
    changeSearchType,
    setScreenLock
  };
};
