import LFCChartsBoxXYZ3 from '_components/charts/LFCChartsBoxXYZ3';
import {TFunction} from 'i18next';
import {round} from 'lodash';
import LFCSingleValues2 from 'pages/lq-job/_components/LFCSingleValues2';
import React, {useCallback, useMemo} from 'react';

import {Card, Grid, Typography} from '@mui/material';
import {green, lightBlue, pink, red} from '@mui/material/colors';

import {CloudDistanceDigGraphBaseData, CloudDistanceDigSearchValue} from '../types';

interface CloudDistanceMaxMinComparisonProps {
  graphBaseData: CloudDistanceDigGraphBaseData;
  searchValue: CloudDistanceDigSearchValue;
  zoomChartOpen: string;
  setZoomChartOpen: (value: string) => void;
  t: TFunction<'translation', undefined, 'translation'>;
  listData: any[];
}

export const CloudDistanceMaxMinComparison = ({
  listData,
  searchValue,
  graphBaseData,
  t,
  zoomChartOpen,
  setZoomChartOpen
}: CloudDistanceMaxMinComparisonProps) => {
  const zoomCharts = useCallback(() => setZoomChartOpen('zoom-chart-1'), [setZoomChartOpen]);
  const zoomChartsClose = useCallback(() => setZoomChartOpen(''), [setZoomChartOpen]);

  const memoizedContent = useMemo(() => {
    const filteredData = listData.filter((b: any) => b.map === searchValue.map);
    const okCount = filteredData.filter((b: any) => b.total_judge === 0).length;
    const ngCount = filteredData.filter((b: any) => b.total_judge === 1).length;
    const totalCount = filteredData.length;
    const okPercent = totalCount === 0 ? 0 : (100 * okCount) / totalCount;
    const ngPercent = totalCount === 0 ? 0 : (100 * ngCount) / totalCount;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{fontWeight: 'bold'}}>{t('最大値最小値比較')}</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <LFCChartsBoxXYZ3
            title={t('距離 推移')}
            source={graphBaseData}
            x={{dsColumn: '', type: 'category'}}
            y={{dsColumn: '', type: 'value'}}
            exportFilename={`CloudDistance_${t('距離推移')}`}
            height={'30vh'}
            top={'40'}
            bottom={'70'}
            zoomChartId={'zoom-chart-1'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
            searchType={searchValue.searchType}
          />
        </Grid>
        <Grid item xs={12} sm={3} justifyContent={'center'} style={{textAlign: 'center'}}>
          <Card sx={{width: '100%', m: 2, p: 1}}>
            <Typography sx={{fontWeight: 'bold'}}>{t('OK範囲基準')}</Typography>
            <Grid container>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'colormap_vmax',
                      headerName: t('上限'),
                      width: '100%',
                      unit: ` mm`,
                      formatter: v => round(v, 1),
                      fontColor: pink[500]
                    }
                  ]}
                  source={{
                    colormap_vmax: graphBaseData?.limits_max
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'colormap_vmin',
                      headerName: t('下限'),
                      width: '100%',
                      unit: ` mm`,
                      formatter: v => round(v, 1),
                      fontColor: lightBlue[500]
                    }
                  ]}
                  source={{
                    colormap_vmin: graphBaseData?.limits_min
                  }}
                />
              </Grid>
              {graphBaseData?.hasMultipleLimit ? (
                <Grid item xs={12}>
                  <LFCSingleValues2
                    columns={[
                      {
                        field: 'colormap_appendix',
                        headerName: t('※規格複数のため最新値'),
                        width: '100%',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      }
                    ]}
                    source={{
                      colormap_appendix: ''
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Card>
          <Card sx={{width: '100%', m: 2, p: 1}}>
            <Typography sx={{fontWeight: 'bold'}}>{t('Cloud Distance 検査結果')}</Typography>
            <Grid container>
              <Grid item xs={12}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'all_count',
                      headerName: t('検査数'),
                      width: '100%',
                      formatter: v => round(v, 1),
                      fontColor: green[500]
                    }
                  ]}
                  source={{
                    all_count: totalCount
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'ok_count',
                      headerName: t('OK判定'),
                      width: '100%',
                      formatter: v => round(v, 1),
                      fontColor: green[500]
                    }
                  ]}
                  source={{
                    ok_count: okCount
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'ng_count',
                      headerName: t('NG判定'),
                      width: '100%',
                      formatter: v => round(v, 1),
                      fontColor: red[500]
                    }
                  ]}
                  source={{
                    ng_count: ngCount
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'ok_percent',
                      headerName: t('OK率'),
                      width: '100%',
                      unit: ' %',
                      formatter: v => round(v, 1),
                      fontColor: green[500]
                    }
                  ]}
                  source={{
                    ok_percent: okPercent
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LFCSingleValues2
                  columns={[
                    {
                      field: 'ng_percent',
                      headerName: t('NG率'),
                      width: '100%',
                      unit: ' %',
                      formatter: v => round(v, 1),
                      fontColor: red[500]
                    }
                  ]}
                  source={{
                    ng_percent: ngPercent
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          {listData.length > 0 &&
          listData.filter((b: any) => b.map === searchValue.map)?.[0]?.hasMultipleLimit
            ? '※規格複数のため最新値'
            : ''}
        </Grid>
      </Grid>
    );
  }, [listData, searchValue, graphBaseData, t, zoomChartOpen]);

  return memoizedContent;
};
