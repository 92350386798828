import { isArray, isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import { Autocomplete, FormControl, TextField } from '@mui/material';

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  value?: any;
  onChange: any;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple: boolean;
  selectItem: any;
  id: string;
  onReset?: boolean;
  doneReset?: any;
}

/**
 * LFCtAutocomplete
 */
const LFCAutocomplete = (props: Props) => {
  const [value, setValue] = React.useState<any>(props.multiple ? [] : null);

  // 選択値が変更されたときの処理
  const onChangeEx = useCallback(
    (event: any, newValue: any) => {
      if (props.multiple) {
        // 複数選択の場合
        // 重複を除外し、ユニークな値のみを保持
        const uniqueValues = Array.from(
          new Set(newValue.map((a: any) => (!a || typeof a === 'string' ? a : a.label)))
        );
        // 型チェックを行い、適切に処理する
        const newLabels = uniqueValues.map((value: unknown) => {
          const label = typeof value === 'string' ? value : (value as {label: string}).label;
          return {label};
        });
        props.onChange({name: props.name, data: uniqueValues}); // 親コンポーネントに変更を通知
        setValue(newLabels); // 内部の状態を更新
      } else {
        // 単一選択の場合
        const newLabel = !newValue || typeof newValue === 'string' ? newValue : newValue.label;
        props.onChange({name: props.name, data: newLabel}); // 親コンポーネントに変更を通知
        setValue(newLabel ? {label: newLabel} : null); // 内部の状態を更新
      }
    },
    [props.name, props.multiple, props.onChange]
  );

  // リセット処理
  useEffect(() => {
    if (props.onReset) {
      setValue(props.multiple ? [] : null); // 複数選択の場合は空配列、単一選択の場合はnullに設定
      props.doneReset(false); // リセット完了を親コンポーネントに通知
    }
  }, [props.onReset, props.doneReset, props.multiple]);

  // 外部からのvalue変更に対する処理
  useEffect(() => {
    if (!isEmpty(props.value) && !isEqual(props.value, value)) {
      setValue(props.value); // 外部から与えられたvalueで内部の状態を更新
    }
  }, [props.value, value]);

  return (
    <FormControl
      variant="outlined"
      style={{minWidth: 200, ...props.style}}
      size={props.size !== undefined ? props.size : 'small'}
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
      disabled={props.disabled !== undefined ? props.disabled : false}
      required={props.required !== undefined ? props.required : false}
      margin={props.margin}
    >
      <Autocomplete
        disabled={props.disabled !== undefined ? props.disabled : false}
        value={value}
        onChange={onChangeEx}
        multiple={props.multiple === undefined ? false : props.multiple}
        id={'combo-box-lfc-auto-' + props.id}
        options={props.selectItem}
        sx={props.style !== undefined ? {...props.style, maxHeight: 500} : {minWidth: 200}}
        size={props.size !== undefined ? props.size : 'small'}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.label}
              style={{wordBreak: 'break-all'}}
              InputLabelProps={{
                shrink: true
              }}
            />
          );
        }}
        ListboxProps={{
          style: {
            fontSize: '0.8rem', // リストのフォントサイズを指定
            overflow: 'auto' // スクロールを有効にする
          }
        }}
      />
    </FormControl>
  );
};
export default LFCAutocomplete;

export const multiSelectData = (listData: any, selVal: any) => {
  if (selVal !== undefined && listData.length > 0) {
    let tmp: any = [];
    if (isArray(selVal)) {
      selVal.forEach((a: string | {label: string}) => {
        const label = !a || typeof a === 'string' ? a : a.label;
        const found = listData.find((b: any) => b.label === label);
        if (found) {
          tmp.push(label); //設定するのは値だけ
        }
      });
      return tmp;
    } else {
      const label = !selVal || typeof selVal === 'string' ? selVal : selVal.label;
      return label; //設定するのは値だけ
    }
  }
  return null;
};
