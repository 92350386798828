import LFCBarChart from '_components/charts/bar/LFCBarChart';
import LFCPieChart from '_components/charts/pie/LFCPieChart';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {renderCellExpand} from '_logics/LFCRenderUtil';
import {adminAPIAccess, getLFCData, soracomAPIAccess} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios, {CancelTokenSource} from 'axios';
import dayjs from 'dayjs';
import {floor} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Alert, Box, Grid, Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DataGridPro, GridColumns} from '@mui/x-data-grid-pro';

type CompRowData = {
  id: string;
  company_id: string;
  company_name: string;
  domain: string;
};

const GridFrame = styled(Grid)(({theme}) => ({
  border: `5px solid ${theme.palette.divider}`,
  borderRadius: '5px'
}));

/**
 * 料金確認画面
 * @returns
 */
const PriceCheckPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const [dsBillsSummariesSims, setDsBillsSummariesSims] = useState<any>([]);
  const [selecetdCompany, setSelecetdCompany] = useState<CompRowData | null>(null);
  const [startProcess, setStartProcess] = useState(false);
  const [screenLock, setScreenLock] = useState(false);
  const cancelSource = useRef<CancelTokenSource | null>(null);

  const [targetSimids, setTargetSimids] = useState<Array<string>>([]);
  const [targetYM, setTargetYM] = useState<string>(dayjs().format('YYYYMM'));
  const [targetYMLine, setTargetYMLine] = useState<Array<string>>([]);
  const [amountMonth, setAmountMonth] = useState<Record<string, any>>({});
  const [amountMonthDtl, setAmountMonthDtl] = useState<Record<string, any>>({});
  const [datatrafficMonth, setDatatrafficMonth] = useState<Record<string, any>>({});
  const [datatrafficMonthDtl, setDatatrafficMonthDtl] = useState<Record<string, any>[]>([]);

  /**
   * 会社一覧データ取得
   */
  const onSearch = () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    setScreenLock(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // 会社情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({companyDB: datas})),
      // Secrets情報取得
      adminAPIAccess({
        apipath: 'GetCorpSettingList',
        parameters: {},
        cancelToken: cancelSource.current.token
      }).then(res => ({companySecrets: res.data})),
      // 請求情報取得
      soracomAPIAccess({
        invoke_name: 'BillsSummariesSims',
        method: 'GET',
        parameters: {},
        cancelToken: cancelSource.current.token
      }).then(res => ({billsSummariesSims: res.data.datas}))
    ])
      .then(res => {
        console.log('res', res);
        if (res.find((r: any) => String(r['reason']).match(/Error:/))) {
          //エラー（キャンセル含む場合は何もしない）
          return;
        }

        const resSum = res.reduce((acc: Record<string, any>, curr: Record<string, any>) => {
          return {...acc, ...curr['value']};
        }, {});

        const gridDatas = resSum['companyDB'].map((item: any, idx: number) => {
          const combInfo = resSum['companySecrets']['scRDSClusterAndDBNames'][String(item['id'])];
          const domain = combInfo.split(':').pop();

          return {
            id: String(idx),
            company_id: String(item['id']),
            company_name: String(item['name']),
            domain: domain
          } as CompRowData;
        });

        setDgRows(gridDatas);

        //請求情報バラす
        const bills = resSum['billsSummariesSims'].flatMap((element: any) => {
          const {costBreakdownList, ...rest} = element;
          return costBreakdownList.map((costs: any) => {
            return {...rest, ...costs};
          });
        });
        setDsBillsSummariesSims(bills);
        console.log(bills);
      })
      .finally(() => {
        setScreenLock(false);
      });
  };

  /**
   * 会社一覧クリックイベント
   * @param selectedData
   */
  const onRowClick = (selectedData: any) => {
    setSelecetdCompany(selectedData.row);
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // マウント時処理
    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {
        field: 'company_id',
        headerName: `${t('会社ID')}`,
        description: `${t('会社ID')}`,
        width: 100,
        renderCell: renderCellExpand
      },
      {
        field: 'domain',
        headerName: `${t('ドメイン')}`,
        description: `${t('ドメイン')}`,
        width: 150,
        renderCell: renderCellExpand
      },
      {
        field: 'company_name',
        headerName: `${t('会社名')}`,
        description: `${t('会社名')}`,
        width: 250,
        renderCell: renderCellExpand
      }
    ]);

    onSearch();

    return () => {
      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  /**
   * 会社選択時処理
   */
  useEffect(() => {
    if (selecetdCompany == null) return;

    setScreenLock(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // 対象SIM情報取得
      soracomAPIAccess({
        invoke_name: 'QuerySims',
        method: 'GET',
        parameters: {
          // group: selecetdCompany.domain,
          tag: selecetdCompany.domain,
          limit: 100,
          search_type: 'or'
        },
        cancelToken: cancelSource.current.token
      }).then(resQuerySims => {
        //対象SIMと金額算出
        let simids: Array<string> = [];
        let amoMonth: Record<string, any> = {};
        let amoMonthDtl: Record<string, any[]> = {};
        resQuerySims.data.datas
          .filter(({tags}: any) => tags['ドメイン'] === selecetdCompany.domain)
          .forEach(({simId}: any) => {
            //対象SIM
            simids.push(simId);

            dsBillsSummariesSims
              .filter((row: any) => row['simId'] === simId)
              .forEach((row: any) => {
                const {yearMonth, amount} = row;

                //月毎
                if (!amoMonth[yearMonth]) {
                  amoMonth[yearMonth] = 0;
                }
                amoMonth[yearMonth] += floor(amount);

                //月-SIM毎
                if (!amoMonthDtl[yearMonth]) {
                  amoMonthDtl[yearMonth] = [];
                }
                amoMonthDtl[yearMonth].push(row);
              });
          });

        console.log('月毎の料金(amountMonth)', amoMonth);
        setAmountMonth(amoMonth);

        console.log('月-SIM毎の料金(amountMonthDtl)', amoMonthDtl);
        setAmountMonthDtl(amoMonthDtl);

        console.log('対象SIM(targetSimids)', simids);
        setTargetSimids(simids);
      })
    ]).finally(() => {
      setScreenLock(false);
    });
  }, [selecetdCompany]);

  useEffect(() => {
    if (targetYM == null) {
      setTargetYMLine([]);
      return;
    }

    if (targetSimids.length === 0) {
      return;
    }

    setStartProcess(true);
    Promise.allSettled(
      targetSimids.map((simid: string) => {
        // SIM情報取得(通信量)
        return soracomAPIAccess({
          invoke_name: 'StatsAirSims',
          method: 'GET',
          parameters: {
            sim_id: simid,
            from: dayjs(targetYM, 'YYYYMM')
              .startOf('month')
              .add(9, 'hour') // 日本時間(UTC+9)を加算
              .unix(),
            to: dayjs(targetYM, 'YYYYMM')
              .endOf('month')
              .add(9, 'hour') // 日本時間(UTC+9)を加算
              .unix(),
            period: 'day'
          }
        }).then(res => ({[simid]: res.data.datas}));
      })
    )
      .then(resStatsAirSims => {
        if (resStatsAirSims.find((r: any) => String(r['reason']).match(/Error:/))) {
          //エラー（キャンセル含む場合は何もしない）
          return;
        }

        const resSum = resStatsAirSims.reduce(
          (acc: Record<string, any>, curr: Record<string, any>) => {
            return {...acc, ...curr['value']};
          },
          {}
        );

        //日付リスト作成
        const from_day = dayjs(targetYM, 'YYYYMM').startOf('month');
        const to_day = dayjs(targetYM, 'YYYYMM').endOf('month');
        let datelist: string[] = [];
        for (let d = from_day; to_day.isAfter(d); d = d.add(1, 'day')) {
          datelist.push(dayjs(d).format('MM-DD'));
        }
        setTargetYMLine(datelist.sort());

        //通信量算出
        let dtMonth: Record<string, any> = {downloadByteSizeTotal: 0, uploadByteSizeTotal: 0};
        let dtMonthDtl: Record<string, any[]> = {};
        Object.entries(resSum).forEach(([simid, dataEntries]: [string, any]) => {
          dataEntries.forEach((entry: any) => {
            const {date, dataTrafficStatsMap} = entry;

            Object.entries(dataTrafficStatsMap).forEach(([plan, stats]: [string, any]) => {
              const {downloadByteSizeTotal, uploadByteSizeTotal} = stats;

              //当月の使用量（MB）
              dtMonth['downloadByteSizeTotal'] += floor(downloadByteSizeTotal / 1024 / 1024, 2);
              dtMonth['uploadByteSizeTotal'] += floor(uploadByteSizeTotal / 1024 / 1024, 2);

              //当月-日-SIM毎の使用量（MB）
              if (!dtMonthDtl[date]) {
                dtMonthDtl[date] = [];
              }
              dtMonthDtl[date].push({
                simid,
                downloadByteSizeTotal: floor(downloadByteSizeTotal / 1024 / 1024, 2),
                uploadByteSizeTotal: floor(uploadByteSizeTotal / 1024 / 1024, 2)
              });
            });
          });
        });
        console.log('当月の通信量', dtMonth);
        setDatatrafficMonth(dtMonth);

        let dtMonthDtlEx: any[] = [];
        Object.entries(dtMonthDtl).forEach(([key, value]) => {
          value.forEach(r => {
            dtMonthDtlEx.push({
              date: dayjs(key, 'YYYYMMDD').format('MM-DD'),
              ...r
            });
          });
        });
        console.log('当月-日-SIM毎の通信量', dtMonthDtlEx);
        setDatatrafficMonthDtl(dtMonthDtlEx);
      })
      .finally(() => {
        setStartProcess(false);
      });
  }, [targetSimids, targetYM]);

  /***
   * 画面ロック状態切替
   */
  useEffect(() => {
    setStartProcess(screenLock);
  }, [screenLock]);

  return (
    <GenericTemplate title={t('menu.page_name.料金確認')}>
      <ProgressBar startProcess={startProcess} screenLock={screenLock} />
      <form ref={formRef}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={12} textAlign="left">
            <Stack direction="row" spacing={4}>
              <LFCButton color="primary" onClick={onSearch}>
                {t('message.最新の情報に更新')}
              </LFCButton>
              <Alert
                severity="info"
                sx={{mb: 1}}
              >{`SORACOMのSIM管理コンソールから該当SIMのタグに「ドメイン＝(ここに表示されている会社毎のドメイン)」を設定してください`}</Alert>
            </Stack>
          </Grid>
          <Grid item xs={4} pr={2}>
            <Box sx={{height: '75vh'}}>
              <DataGridPro
                // apiRef={props.apiRef}
                columns={dgColumns}
                rows={dgRows}
                density={'compact'}
                // pagination
                // autoPageSize
                // components={{
                //   Toolbar: CustomToolbar,
                // }}
                // componentsProps={{
                //   toolbar: {
                //     rows: props.rows,
                //     fieldname: exportField,
                //     filename: exportFileName,
                //   },
                // }}
                onCellClick={onRowClick}
                // disableSelectionOnClick
                showCellRightBorder
                showColumnRightBorder
                // initialState={{ pinnedColumns: { right: ["actions"] } }}
                // initialState={
                //   props.initialState
                //     ? { pinnedColumns: props.initialState }
                //     : {
                //         pinnedColumns: {
                //           left: [],
                //           right: [],
                //         },
                //       }
                // }
                // rowHeight={38}
              />
            </Box>
          </Grid>
          <Grid item xs={8} mt={2}>
            <GridFrame container spacing={2}>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  color="primary"
                  value={targetYM}
                  exclusive
                  onChange={(event: React.MouseEvent<HTMLElement>, value: any) => {
                    if (value == null) {
                      //2度押しでOFFにしない対応
                      return;
                    }
                    setTargetYM(value);
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value={dayjs().subtract(2, 'month').format('YYYYMM')}>
                    {dayjs().subtract(2, 'month').format('YYYY-MM')}
                  </ToggleButton>
                  <ToggleButton value={dayjs().subtract(1, 'month').format('YYYYMM')}>
                    {dayjs().subtract(1, 'month').format('YYYY-MM')}
                  </ToggleButton>
                  <ToggleButton value={dayjs().format('YYYYMM')}>
                    {dayjs().format('YYYY-MM')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={4}>
                <LFCSingleValue
                  title="月額料金"
                  val={(amountMonth[targetYM] || 0)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  unit="円"
                ></LFCSingleValue>
                <Grid container>
                  <Grid item xs={6} mt={2}>
                    <LFCSingleValue
                      title="通信量(upload)"
                      val={Number.parseFloat(datatrafficMonth['uploadByteSizeTotal'] || 0)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      unit="MB"
                    ></LFCSingleValue>
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <LFCSingleValue
                      title="通信量(download)"
                      val={Number.parseFloat(datatrafficMonth['downloadByteSizeTotal'] || 0)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      unit="MB"
                    ></LFCSingleValue>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <LFCPieChart
                  name="月額料金内訳"
                  data={amountMonthDtl[targetYM]}
                  nameKey="simId"
                  valueKey="amount"
                  valueUnit="円"
                  minPercent={2}
                  height="25vh"
                ></LFCPieChart>
              </Grid>
              <Grid item xs={12}>
                <LFCBarChart
                  name={`${t('通信量(upload)内訳')}`}
                  data={datatrafficMonthDtl}
                  x={{type: 'category', dsColumn: 'date', data: targetYMLine}}
                  y={{type: 'value', name: '(MB)', dsColumn: 'uploadByteSizeTotal'}}
                  series="simid"
                  height="25vh"
                  stacked
                  exportData={datatrafficMonthDtl}
                  exportFields={{
                    date: t('日付'),
                    simid: t('SIMID'),
                    uploadByteSizeTotal: t('通信量(MB)')
                  }}
                  exportFilename={`${t('通信量(upload)内訳')})`}
                />
              </Grid>
              <Grid item xs={12}>
                <LFCBarChart
                  name={`${t('通信量(download)内訳')}`}
                  data={datatrafficMonthDtl}
                  x={{type: 'category', dsColumn: 'date', data: targetYMLine}}
                  y={{type: 'value', name: '(MB)', dsColumn: 'downloadByteSizeTotal'}}
                  series="simid"
                  height="25vh"
                  stacked
                  exportData={datatrafficMonthDtl}
                  exportFields={{
                    date: t('日付'),
                    simid: t('SIMID'),
                    downloadByteSizeTotal: t('通信量(MB)')
                  }}
                  exportFilename={`${t('通信量(download)内訳')})`}
                />
              </Grid>
            </GridFrame>
          </Grid>
        </Grid>
      </form>
    </GenericTemplate>
  );
};

export default PriceCheckPage;
