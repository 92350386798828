import React, {useMemo, useState} from 'react';

import {Grid} from '@mui/material';

import {CustomSelect} from '../../CustomSelect';
import {
  CloudDistanceGraphBaseData,
  CloudDistanceListData,
  CloudDistanceSearchValue
} from '../../types';
import {CloudDistanceGraphPanel} from './components/CloudDistanceGraphPanel';
import {CloudDistanceSubPanel} from './components/CloudDistanceSubPanel';

interface CloudDistanceMainPanelProps {
  mapData: any;
  selectedMaps: any;
  setSelectedMaps: any;
  graphClick: any;
  graphBaseDatas: CloudDistanceGraphBaseData[];
  listData: CloudDistanceListData[];
  t: any;
  searchValue: CloudDistanceSearchValue;
}

export const CloudDistanceMainPanel = ({
  mapData,
  selectedMaps,
  setSelectedMaps,
  listData,
  t,
  searchValue,
  graphBaseDatas,
  graphClick
}: CloudDistanceMainPanelProps) => {
  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  const memoizedContent = useMemo(() => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} xl={2}>
          <CustomSelect
            mapSeries={mapData}
            selectedValues={selectedMaps}
            setSelectedValues={setSelectedMaps}
            t={t}
          />
        </Grid>
        <Grid item xs={12} sm={9} xl={10}>
          {graphBaseDatas.map((row, index: number) => {
            return (
              <Grid container spacing={1} key={index}>
                <Grid item xs={12} sm={10} key={'g_' + row.map}>
                  <CloudDistanceGraphPanel
                    selectedValue={row.map}
                    graphBaseData={row}
                    searchValue={searchValue}
                    graphClick={graphClick}
                    zoomChartOpen={zoomChartOpen}
                    setZoomChartOpen={setZoomChartOpen}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  key={'h_' + row.map}
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CloudDistanceSubPanel selectedValue={row.map} listData={listData} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }, [
    // mapData,
    selectedMaps,
    setSelectedMaps,
    // listData,
    t,
    // searchValue,
    graphBaseDatas,
    graphClick,
    zoomChartOpen,
    setZoomChartOpen
  ]);

  return memoizedContent;
};
