import {Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, isNull, round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBar from '_components/charts/LFCChartsBar';
import LFCChartsComboHH from '_components/charts/LFCChartsComboHH';
import LFCChartsHeatMap from '_components/charts/LFCChartsHeatMap';
import ProgressBar from '_components/ProgressBar';
import LFCSideMenu from '_components/search-conditions/LFCSideMenu';
import ProgressMonitor from '_components/search-conditions/ProgressMonitor';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {FORMAT_YYYYMMDDHHmmSS} from '_logics/LFCConst';
import {rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import DetailDig from '../inspectionresults/components/DetailDig';
import {
  PAGEID_LQ_INSPECTION_PROGRESS_MONITOR,
  LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN
} from '_logics/LFCPageId';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {useTranslation} from 'react-i18next';

/**
 * あんどん
 * @returns
 */
const InspectionProgressMonitorPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const getDefaultCondition = useGetDefaultCondition();

  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(
      PAGEID_LQ_INSPECTION_PROGRESS_MONITOR,
      LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN
    ) ?? {
      select_time: '00:00',
      working_time: 24,
      target_number: '1000',
      work: localStorage.getItem('work')?.toString().split(','),
      deviceid: localStorage.getItem('deviceid')?.toString().split(','),
      dongleid: localStorage.getItem('dongleid')?.toString().split(',')
    }
  );

  // useEffect(() => {
  //   setSearchValue({
  //     ...searchValue,
  //     work: [t('すべて')],
  //     deviceid: [t('すべて')],
  //     dongleid: [t('すべて')]
  //   });
  // }, [t]);
  useEffect(() => {
    const keywords = ['ALL', 'すべて'];
    const updatedSearchValue = {...searchValue};

    ['work', 'deviceid', 'dongleid'].forEach(field => {
      if (searchValue[field].some((value: any) => keywords.includes(value))) {
        updatedSearchValue[field] = [t('すべて')];
      }
    });
    localStorage.setItem('work', updatedSearchValue.work);
    localStorage.setItem('deviceid', updatedSearchValue.deviceid);
    localStorage.setItem('dongleid', updatedSearchValue.dongleid);
    setSearchValue(updatedSearchValue);
  }, [t]);

  const [data60351, setData60351] = useState({total_count: '0', ok_count: '0', ng_count: '0'});
  const [data60009, setData60009] = useState({});
  const [data60006, setData60006] = useState({});
  const [data60008, setData60008] = useState({
    deviceid: '',
    dongleid: '',
    lq_time: '',
    serial: '',
    total_judge: 0,
    uuid: '',
    work: ''
  });
  const [data60347, setData60347] = useState([]);
  const [data60352, setData60352] = useState([]);
  const [data60353, setData60353] = useState([]);
  const [dispDate, setDispDate] = useState('');
  const delay = 30000;
  const [fixPeriod, setFixPeriod] = useState<{
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }>({startDate: dayjs(), endDate: dayjs()});

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    let tmp: string | null = localStorage.getItem(
      'lfc.searchcondition.inspection-progress-monitor'
    );
    let getData: any = !isNull(tmp) ? JSON.parse(tmp) : undefined;
    if (!isEmpty(getData)) {
      setSearchValue({
        ...searchValue,
        select_time: getData.select_time,
        working_time: getData.working_time,
        target_number: getData.target_number
      });
    }
    doSearch();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'lfc.searchcondition.inspection-progress-monitor',
      JSON.stringify({
        select_time: searchValue.select_time,
        working_time: searchValue.working_time,
        target_number: searchValue.target_number
      })
    );
  }, [searchValue]);

  const doSearch = () => {
    // 対象期間算出
    const now = dayjs();
    let fromDt = dayjs(`${now.format('YYYY-MM-DD')} ${searchValue.select_time}`);

    // 稼働開始時刻より前の場合、前日の同時刻を開始時刻とする。
    if (searchValue.select_time > now.format('HH:mm')) {
      // 稼働開始時刻
      fromDt = fromDt.add(-1, 'd');
    }
    const toDt = fromDt.add(1, 'd');

    setDispDate(fromDt.format(FORMAT_YYYYMMDDHHmmSS));
    setFixPeriod({startDate: fromDt, endDate: toDt});

    const conditionsDate = {
      select_datetime_from: fromDt.format(),
      select_datetime_to: toDt.format()
    };
    const conditionsOther = {
      work:
        localStorage.getItem('work') !== t('すべて')
          ? `{${localStorage.getItem('work')?.toString().split(',')}}`
          : null,
      deviceid:
        localStorage.getItem('deviceid') !== t('すべて')
          ? `{${localStorage.getItem('deviceid')?.toString().split(',')}}`
          : null,
      dongleid:
        localStorage.getItem('dongleid') !== t('すべて')
          ? `{${localStorage.getItem('dongleid')?.toString().split(',')}}`
          : null
    };
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60351,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('検査数と不良数')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60351(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60009,
        parameters: {
          working_time: searchValue.working_time,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('検査間隔')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60009(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60006,
        parameters: {
          working_time: searchValue.working_time,
          target_number: searchValue.target_number,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('タクトタイム')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60006(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60352,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData60352,
        name: `${t('実績数と不良率')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60353,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData60353,
        name: `${t('不良数')}(${t('部位別')})`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60008,
        parameters: {
          ...conditionsOther
        },
        name: `${t('最終実行結果')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60008(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60347,
        parameters: {
          ...conditionsOther
        },
        ds_state: setData60347,
        name: `${t('最終実行結果の部位別結果')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * 自動更新処理
   */
  useInterval(doSearch, delay);

  useEffect(() => {
    // マウント時処理
    doSearch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [openUuid, setOpenUuid] = React.useState('');
  const [openMap, setOpenMap] = React.useState('');

  /**
   * 明細選択
   * @param params
   * @param event
   * @param details
   */
  const onClickHeat = (params: {x: string; y: string}) => {
    setOpenUuid(data60008.uuid);
    setOpenMap(params.x);
    setOpen(true);
  };

  return (
    <GenericTemplate title={t('menu.page_name.検査進捗モニタ')}>
      <ProgressBar startProcess={startProcess} />
      <LFCSideMenu onClose={() => doSearch()}>
        <ProgressMonitor
          values={searchValue}
          onChange={res => setSearchValue(res)}
          pageId={PAGEID_LQ_INSPECTION_PROGRESS_MONITOR}
          locationId={LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN}
        ></ProgressMonitor>
      </LFCSideMenu>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <LFCTitledCard title={t('本日')} titleSub={`${dispDate} ～ `}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <LFCOkNgPie source={data60351} height="300px" />
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title={`${t('検査間隔')}(${t('秒')})`} transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'minimum_ct',
                        headerName: `${t('最小')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'average_ct',
                        headerName: `${t('平均')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'maximum_ct',
                        headerName: `${t('最大')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60009}
                  />
                </LFCTitledCard>
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title={`${t('タクトタイム')}(${t('秒')})`} transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'tt_plan',
                        headerName: t('計画'),
                        formatter: v => round(v, 1),
                        width: '46%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'tt_results',
                        headerName: t('実績'),
                        formatter: v => round(v, 1),
                        width: '46%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60006}
                  />
                </LFCTitledCard>
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>
        <Grid item xs={12} md={7} lg={8} xl={9} mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <LFCChartsComboHH
                title={t('実績数と不良率')}
                source={data60352}
                x={fixPeriod}
                yLeft={{
                  type: 'bar',
                  name: `${t('実績数')}`,
                  dsColumn: 'total_count'
                }}
                yRight={{
                  type: 'line',
                  name: `${t('不良率')}(%)`,
                  dsColumn: 'ng_par',
                  markPoint: {
                    data: [
                      {type: 'min', itemStyle: {color: '#E91E63'}},
                      {type: 'max', itemStyle: {color: '#2196F3'}}
                    ]
                  },
                  markLine: {
                    symbol: 'arrow',
                    data: [
                      {
                        type: 'average',
                        label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                      }
                    ]
                  }
                }}
                color={[green[500], red[500]]}
                height="40vh"
                exportData={data60352}
                exportFields={{
                  lq_time: t('日付'),
                  total_count: t('実績数'),
                  ng: t('不良数'),
                  ng_par: t('不良率')
                }}
                exportFilename={`${t('実績数と不良率')}`}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <LFCChartsBar
                title={`${t('不良数')}(${t('部位別')})`}
                source={data60353}
                x={{type: 'category', dsColumn: 'map'}}
                y={{type: 'value', dsColumn: 'count'}}
                exportData={data60353}
                exportFields={{
                  map: t('部位'),
                  count: t('件数')
                }}
                exportFilename={`${t('不良数')}(${t('部位別')})`}
              />
            </Grid>
            <Grid item xs={12}>
              <LFCTitledCard title={t('最終実行結果')} transparent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LFCSingleValues
                      columns={[
                        {
                          field: 'total_judge',
                          headerName: t('総合判定'),
                          formatter: rendTotalJudgeDisp
                        },
                        {field: 'lq_time', headerName: t('日時')},
                        {field: 'serial', headerName: t('シリアルNo')},
                        {field: 'work', headerName: t('機種')},
                        {field: 'deviceid', headerName: t('マシン')},
                        {field: 'dongleid', headerName: t('オペレータ')}
                      ]}
                      source={data60008}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LFCChartsHeatMap
                      title={`${t('判定結果')}(${t('ジョブ')}×${t('部位')})`}
                      source={data60347}
                      x={{dsColumn: 'map'}}
                      y={{dsColumn: 'job_type_name'}}
                      series="judge"
                      visual_map={{
                        min: 0,
                        max: 1,
                        show: false
                      }}
                      exportData={data60347}
                      exportFields={{
                        job_type_name: t('ジョブ'),
                        map: t('部位'),
                        judge: t('判定')
                      }}
                      exportFilename={`${t('部位別結果')}`}
                      onClick={onClickHeat}
                    />
                  </Grid>
                </Grid>
              </LFCTitledCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DetailDig open={open} onClose={() => setOpen(false)} uuid={openUuid} map={openMap} />
    </GenericTemplate>
  );
};

export default InspectionProgressMonitorPage;
