import {TFunction} from 'i18next';
import {ChangeEvent, useCallback, useMemo} from 'react';

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Tooltip
} from '@mui/material';

interface CustomSelectProps {
  mapSeries: string[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  t: TFunction<'translation', undefined, 'translation'>;
}

export const CustomSelect = ({
  mapSeries,
  selectedValues,
  setSelectedValues,
  t
}: CustomSelectProps) => {
  const handleChange = useCallback(
    (event: any, item: string) => {
      event.stopPropagation();
      const newSelectedValues = mapSeries.filter(value =>
        selectedValues.includes(item)
          ? selectedValues.includes(value) && value !== item
          : selectedValues.includes(value) || value === item
      );
      setSelectedValues(newSelectedValues);
    },
    [mapSeries, selectedValues, setSelectedValues]
  );

  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedValues(mapSeries);
      } else {
        setSelectedValues([]);
      }
    },
    [mapSeries, setSelectedValues]
  );

  const memoizedListItems = useMemo(
    () =>
      mapSeries.map((item, index) => (
        <ListItemButton
          key={index}
          role="listitem"
          onClick={event => handleChange(event, item)}
          sx={{
            bgcolor: selectedValues.includes(item) ? 'divider' : ''
          }}
        >
          <ListItemText id={`transfer-item-${index}-label`} primary={item} />
        </ListItemButton>
      )),
    [mapSeries, selectedValues, handleChange]
  );

  return (
    <Card>
      <Box height="65vh" display="flex" flexDirection="column">
        <CardHeader
          sx={{px: 0, py: 1}}
          avatar={
            <Tooltip title={t('全部位選択')}>
              <Checkbox
                checked={mapSeries.length > 0 && selectedValues.length === mapSeries.length}
                onChange={handleSelectAll}
                color="primary"
              />
            </Tooltip>
          }
          title={t('部位名選択')}
        />
        <Divider />
        <List
          sx={{
            flex: 1,
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0
          }}
          dense
          component="div"
          role="list"
        >
          {memoizedListItems}
        </List>
      </Box>
    </Card>
  );
};
