import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {CloudDistanceDigGraphBaseData} from 'pages/lq-job/cloud-distance/components/cloud-distance-dig/types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useTheme} from '@mui/material';
import {lightBlue, pink} from '@mui/material/colors';

/**
 * 引数
 */
interface Props {
  title: string;
  source: CloudDistanceDigGraphBaseData;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  xAxisName?: string;
  onClick?: (prm: {data: any}) => void;
  limit?: any;
  limitRow?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  xLineMax?: number;
  xRenge?: number;
  averageUpperDistance?: any;
  medianUpperDistance?: any;
  averagePlus3SD?: any;
  averageMinus3SD?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarWorkCloudDistance2 = (props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [rangeCounts, setRangeCounts] = useState<number[]>([]);
  const [ranges, setRanges] = useState<number[]>([]);

  const [exportData, setExportData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [x_list, setX_list] = useState<string[]>([]);

  useEffect(() => {
    if (props.xRenge !== undefined && !isNaN(props.xRenge) && props.xRenge > 0) {
      const x_start = Math.min(props.source.lower_distance_min, props.source.limits_min);
      const x_end = Math.max(props.source.lower_distance_max, props.source.limits_max);
      const x_range = props.xRenge;

      if (x_start > x_end) {
        console.error('Invalid range: lower_max is less than lower_min.');
        return;
      }

      let current_value = x_start;
      let newRanges: number[] = [];
      let iterationCount = 0; // 安全対策のためのループ回数制限
      const maxIterations = 1000; // 適切な上限回数を設定

      while (current_value <= x_end) {
        if (iterationCount++ > maxIterations) {
          console.error('Infinite loop detected. Breaking the loop.');
          break;
        }
        newRanges.push(Number(current_value));
        current_value += x_range;
      }
      newRanges.push(Number(current_value));

      newRanges.sort((a, b) => a - b);
      setRanges(newRanges);

      if (newRanges.length > 1) {
        const newRangeCounts = Array(newRanges.length - 1).fill(0);

        props.source.data_lower_distance.flat().forEach((value: number) => {
          for (let i = 0; i < newRanges.length - 1; i++) {
            if (value >= newRanges[i] && value < newRanges[i + 1]) {
              newRangeCounts[i]++;
              break;
            }
          }
        });
        setRangeCounts(newRangeCounts);
      } else {
        console.log('Ranges array does not have enough elements.');
      }
    }
  }, [
    props.xRenge,
    props.source.data_lower_distance,
    props.source.data_upper_limits,
    props.source.data_lower_limits
  ]);

  useEffect(() => {
    // x_listの計算
    const newX_list = ranges
      .slice(0, -1)
      .map((value, index) => `${value.toFixed(2)}_${ranges[index + 1].toFixed(2)}`);
    setX_list(newX_list);

    // エクスポートデータの設定
    let _exportData: any[] = [];
    if (rangeCounts.length === newX_list.length && rangeCounts.length > 0) {
      _exportData = rangeCounts.map((value: any, i: number) => {
        return {
          距離: newX_list[i],
          カウント数: value
        };
      });
      setExportData(_exportData);
    } else {
      console.error('rangeCounts and x_list lengths do not match or are empty');
      setExportData([]);
    }

    // 合計カウントを計算し、状態として保存
    const newTotalCount = rangeCounts.reduce((sum, count) => sum + count, 0);
    setTotalCount(newTotalCount);
  }, [rangeCounts, ranges]);

  const makeMin = () => {
    if (x_list[0] !== undefined) {
      let tmp: any = x_list[0].split('_');
      return Math.min(Number(tmp[0]), props.source.limits_min);
    } else {
      return null;
    }
  };

  const makeMax = () => {
    if (x_list[x_list.length - 1] !== undefined) {
      let tmp: any = x_list[x_list.length - 1].split('_');
      return Math.max(Number(tmp[1]), props.source.limits_max);
    } else {
      return null;
    }
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: [
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 0
            },
            {
              type: 'value',
              axisLabel: {
                rotate: '15',
                fontSize: 10
              },
              min: makeMin(),
              max: makeMax(),
              xAxisIndex: 1,
              show: false,
              axisPointer: {
                show: true
              }
            }
          ],
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            yAxisIndex: 0,
            max: Math.max(...rangeCounts),
            offset: 0,
            axisLine: {
              onZero: false
            }
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            selected: {
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均-3σ': false,
              '平均+3σ': false
            },
            formatter: (prm: any) => {
              return t(prm);
            },
            data: [
              {
                name: t('上限'),
                icon: 'circle'
              },
              {
                name: t('下限'),
                icon: 'circle'
              },
              {
                name: t('中央'),
                icon: 'circle'
              },
              {
                name: t('平均'),
                icon: 'circle'
              },
              {
                name: t('平均-3σ'),
                icon: 'circle'
              },
              {
                name: t('平均+3σ'),
                icon: 'circle'
              }
            ],

            top: props.zoomStatus ? '95%' : '90%',
            textStyle: {
              fontSize: 10
            }
          },
          series: [
            {
              name: t('個'),
              data: rangeCounts,
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 0,
              color: theme.palette.primary.main
            },
            {
              name: t('上限'),
              type: 'line',
              xAxisIndex: 1,
              color: pink[500],
              markLine: {
                label: {
                  color: pink[500],
                  formatter: t('上限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'dotted'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('上限'),
                    xAxis: props.source.limits_max
                  }
                ]
              }
            },

            {
              name: t('下限'),
              type: 'line',
              xAxisIndex: 1,
              color: lightBlue[500],
              markLine: {
                label: {
                  color: lightBlue[500],
                  formatter: t('下限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'dotted'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('下限'),
                    xAxis: props.source.limits_min
                  }
                ]
              }
            },
            {
              name: t('中央'),
              type: 'line',
              xAxisIndex: 1,
              color: colorPallet[1],
              markLine: {
                label: {
                  color: colorPallet[1],
                  formatter: t('中央'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('中央'),
                    xAxis: props.medianUpperDistance
                  }
                ]
              }
            },
            {
              name: t('平均'),
              type: 'line',
              xAxisIndex: 1,
              color: colorPallet[2],
              markLine: {
                label: {
                  color: colorPallet[2],
                  formatter: t('平均'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均'),
                    xAxis: props.averageUpperDistance
                  }
                ]
              }
            },
            {
              name: t('平均+3σ'),
              type: 'line',
              xAxisIndex: 1,
              color: colorPallet[3],
              markLine: {
                label: {
                  color: colorPallet[3],
                  formatter: t('平均+3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均+3σ'),
                    xAxis: props.averagePlus3SD
                  }
                ]
              }
            },
            {
              name: t('平均-3σ'),
              type: 'line',
              xAxisIndex: 1,
              color: colorPallet[4],
              markLine: {
                label: {
                  color: colorPallet[4],
                  formatter: t('平均-3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均-3σ'),
                    xAxis: props.averageMinus3SD
                  }
                ]
              }
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              if (prm.seriesName === t('個')) {
                return (
                  prm.name +
                  'mm<br>' +
                  t('検査数') +
                  ':' +
                  prm.value +
                  t('件') +
                  '<br>' +
                  t('構成比') +
                  ':' +
                  ((prm.value / totalCount) * 100).toFixed(1) +
                  '%'
                );
              } else {
                return prm.data.name === t('上限')
                  ? t('上限') + ':' + props.source.limits_max + 'mm'
                  : prm.data.name === t('下限')
                    ? t('下限') + ':' + props.source.limits_min + 'mm'
                    : prm.data.name === t('中央')
                      ? t('中央') + ':' + props.medianUpperDistance + 'mm'
                      : prm.data.name === t('平均')
                        ? t('平均') + ':' + props.averageUpperDistance + 'mm'
                        : prm.data.name === t('平均-3σ')
                          ? t('平均-3σ') + ':' + props.averageMinus3SD + 'mm'
                          : prm.data.name === t('平均+3σ')
                            ? t('平均+3σ') + ':' + props.averagePlus3SD + 'mm'
                            : '';
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '75',
            right: '15',
            bottom: props.bottom,
            top: props.top
          }
        }}
        exportData={exportData}
        exportFields={{
          距離: t('距離'),
          カウント数: t('カウント数')
        }}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params.seriesName === t('個') && props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
};
export default LFCChartsBarWorkCloudDistance2;
