import {useMySettings} from '_contexts/MySettingsProvider';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {getLFCData} from '_logics/LFCUtil';
import axios, {CancelTokenSource} from 'axios';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {
  CloudDistanceGraphSelectData,
  CloudDistanceSearchValue
} from 'pages/lq-job/cloud-distance/types';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from 'tss-react/mui';

import {GridValueFormatterParams} from '@mui/x-data-grid';

import {CloudDistanceDigGraphBaseData, CloudDistanceDigSearchValue} from '../types';

const useStyles = makeStyles()(theme => {
  return {
    // root: {
    //   '& div': {
    //     '&::-webkit-scrollbar': {
    //       display: 'none'
    //     }
    //   }
    // },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    }
  };
});

interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: CloudDistanceGraphSelectData;
  searchValue: CloudDistanceSearchValue;
}

export const useCloudDistanceDig = (props: Props) => {
  const graphBaseDataInit = {
    data_upper_distance: [],
    data_lower_distance: [],
    data_upper_limits: [],
    data_lower_limits: [],
    upper_distance_min: 0,
    upper_distance_max: 0,
    lower_distance_min: 0,
    lower_distance_max: 0,
    limits_min: 0,
    limits_max: 0,
    hasMultipleLimit: false,
    xlist: [],
    detail: [], // listDataの行情報を持つ
    map: ''
  };

  const {t} = useTranslation();
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [screenLock, setScreenLock] = useState(false);

  const [graphBaseData, setGraphBaseData] =
    useState<CloudDistanceDigGraphBaseData>(graphBaseDataInit);

  const [listData, setListData] = useState<any>([]);
  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');
  const [detailListOpen, setDetailListOpen] = useState(false);
  const [autoCompleteReset, setAutoCompleteReset] = useState<boolean>(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  const cancelSource = useRef<CancelTokenSource | null>(null);

  const CloudDistanceDetailColumn: {
    field: string;
    headerName: string;
    description: string;
    sortable: boolean;
    width: number;
    valueFormatter?: (prms: GridValueFormatterParams) => string | undefined;
  }[] = [
    {
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: false,
      width: 100
    },
    {
      field: 'uuid',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: false,
      width: 200
    },
    {
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: false
    },
    {
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 200,
      sortable: false,
      valueFormatter: (prms: GridValueFormatterParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {
      field: 'upper_distance',
      headerName: t('距離 最大値'),
      description: t('距離 最大値'),
      width: 200,
      sortable: false
    },
    {
      field: 'lower_distance',
      headerName: t('距離 最小値'),
      description: t('距離 最小値'),
      width: 200,
      sortable: false
    },
    {
      field: 'upper_limit',
      headerName: t('上限'),
      description: t('上限'),
      width: 200,
      sortable: false
    },
    {
      field: 'lower_limit',
      headerName: t('下限'),
      description: t('下限'),
      width: 200,
      sortable: false
    }
  ];
  //入力中条件
  const [tmpSearchValue, setTmpSearchValue] = useState<CloudDistanceDigSearchValue>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: props.datas.mapName,
    searchType: 2
  });
  //確定条件
  const [searchValue, setSearchValue] = useState<CloudDistanceDigSearchValue>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: props.datas.mapName,
    searchType: 2
  });

  /***
   * 画面ロック状態切替
   */
  useEffect(() => {
    setStartProcess(screenLock);
  }, [screenLock]);

  /***
   * 初期処理
   */
  useEffect(() => {
    if (!props.open) return;

    setListData([]);
    const locTmpSearchValue = {
      ...tmpSearchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: props.datas.mapName,
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    };

    setScreenLock(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42009,
        parameters: {},
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['work']};
          });
          setWork(tmp);
        })
        .catch(e => e),

      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 30004,
        parameters: {
          select_date_from: locTmpSearchValue.select_date_from,
          select_date_to: locTmpSearchValue.select_date_to,
          work: !isEmpty(locTmpSearchValue.work)
            ? '{' + locTmpSearchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(tmpSearchValue.deviceid)
            ? '{' + locTmpSearchValue.deviceid.join(',') + '}'
            : null,
          judge: locTmpSearchValue.judge === 9 ? null : locTmpSearchValue.judge
        },
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['map']};
          });
          setMap(tmp);
        })
        .catch(e => e),

      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42010,
        parameters: {},
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['deviceid']};
          });
          setDeviceid(tmp);
        })
        .catch(e => e)
    ]).finally(() => {
      setTmpSearchValue(locTmpSearchValue);
      setScreenLock(false);
    });
  }, [props.open]);

  // 入力値変更イベント
  const handleChange = useCallback((event: any) => {
    setTmpSearchValue(prev => ({...prev, [event.target.name]: event.target.value}));
  }, []);

  const autoOnChange = useCallback((relayDatas: any) => {
    setTmpSearchValue(prev => ({...prev, [relayDatas.name]: relayDatas.data}));
  }, []);

  /**
   * 集計単位変更イベント
   */
  const changeSearchType = useCallback(() => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');
    setStartProcess(false);

    // setListData([]);
  }, [cancelSource]);

  /***
   * X軸生成処理
   */
  const dateCalc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [searchValue.select_date_from, searchValue.select_date_to, searchValue.searchType]);

  /**
   * 検索処理
   */
  const doSearch = useCallback(async () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    //検索条件FIX
    setSearchValue(tmpSearchValue);

    setListData([]);

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 30003,
        parameters: {
          select_date_from: tmpSearchValue.select_date_from,
          select_date_to: tmpSearchValue.select_date_to,
          work: !isEmpty(tmpSearchValue.work) ? '{' + tmpSearchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(tmpSearchValue.deviceid)
            ? '{' + tmpSearchValue.deviceid.join(',') + '}'
            : null,
          judge: tmpSearchValue.judge === 9 ? null : tmpSearchValue.judge,
          map: tmpSearchValue.map
        },
        name: 'id-30003',
        cancelToken: cancelSource.current.token,
        t
      }).then(data => {
        setListData(
          data.map((e: any) => {
            return {
              ...e,
              id: e.idx
            };
          })
        );
      })
    ]).finally(() => {
      setStartProcess(false);
    });
  }, [cancelSource, tmpSearchValue, enqueueSnackbar, t]);

  /**
   * 検索リセット処理
   */
  const restSearch = useCallback(() => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');
    setStartProcess(false);

    setTmpSearchValue({
      ...tmpSearchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: props.datas.mapName,
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
    setAutoCompleteReset(true);
  }, [cancelSource, tmpSearchValue, props.searchValue, props.datas.mapName]);

  const listDigClose = useCallback(() => {
    setDetailListOpen(false);
  }, []);

  /**
   * チャートデータ生成処理
   */
  useEffect(() => {
    const tmp_all: CloudDistanceDigGraphBaseData = graphBaseDataInit;

    if (listData.length === 0) {
      setGraphBaseData(tmp_all);
      return;
    }

    if (searchValue.searchType === 0) {
      //検査毎
      listData.forEach((r: any) => {
        tmp_all.xlist.push(r.idx);
        tmp_all.detail.push(r);
        //検査毎の場合だけ数値が直に入ってる
        tmp_all.data_upper_distance.push(Number(r.upper_distance));
        tmp_all.data_lower_distance.push(Number(r.lower_distance));
        tmp_all.data_upper_limits.push(Number(r.upper_limit));
        tmp_all.data_lower_limits.push(Number(r.lower_limit));
      });
      tmp_all.upper_distance_min = parseFloat(
        Math.min(...(tmp_all.data_upper_distance as Array<number>)).toFixed(2)
      );
      tmp_all.upper_distance_max = parseFloat(
        Math.max(...(tmp_all.data_upper_distance as Array<number>)).toFixed(2)
      );
      tmp_all.lower_distance_min = parseFloat(
        Math.min(...(tmp_all.data_lower_distance as Array<number>)).toFixed(2)
      );
      tmp_all.lower_distance_max = parseFloat(
        Math.max(...(tmp_all.data_lower_distance as Array<number>)).toFixed(2)
      );
      tmp_all.limits_min = parseFloat(
        Math.min(...(tmp_all.data_lower_limits as Array<number>)).toFixed(2)
      );
      tmp_all.limits_max = parseFloat(
        Math.max(...(tmp_all.data_upper_limits as Array<number>)).toFixed(2)
      );
    } else {
      dateCalc().forEach((lq_time_string: string) => {
        let tmp_upper_distance: number[] = [];
        let tmp_lower_distance: number[] = [];
        let tmp_upper_limits: number[] = [];
        let tmp_lower_limits: number[] = [];
        let tval = 0;
        listData.forEach((r: any) => {
          if (
            (searchValue.searchType === 1 && r.year_month_day_hour === lq_time_string) ||
            (searchValue.searchType === 2 && r.year_month_day === lq_time_string) ||
            (searchValue.searchType === 3 && r.year_month === lq_time_string)
          ) {
            tmp_upper_distance.push(Number(r.upper_distance));
            tmp_lower_distance.push(Number(r.lower_distance));
            tmp_upper_limits.push(Number(r.upper_limit));
            tmp_lower_limits.push(Number(r.lower_limit));
          }
        });
        tmp_all.xlist.push(lq_time_string);
        tmp_all.detail.push([]);
        tmp_all.data_upper_distance.push(tmp_upper_distance);
        tmp_all.data_lower_distance.push(tmp_lower_distance);
        tmp_all.data_upper_limits.push(tmp_upper_limits);
        tmp_all.data_lower_limits.push(tmp_lower_limits);

        tval = parseFloat(Math.min(...tmp_upper_distance).toFixed(2));
        if (tval < tmp_all.upper_distance_min) {
          tmp_all.upper_distance_min = tval;
        }
        tval = parseFloat(Math.max(...tmp_upper_distance).toFixed(2));
        if (tmp_all.upper_distance_max < tval) {
          tmp_all.upper_distance_max = tval;
        }
        tval = parseFloat(Math.min(...tmp_lower_distance).toFixed(2));
        if (tval < tmp_all.lower_distance_min) {
          tmp_all.lower_distance_min = tval;
        }
        tval = parseFloat(Math.max(...tmp_lower_distance).toFixed(2));
        if (tmp_all.lower_distance_max < tval) {
          tmp_all.lower_distance_max = tval;
        }
        tval = parseFloat(Math.min(...tmp_lower_limits).toFixed(2));
        if (tval < tmp_all.limits_min) {
          tmp_all.limits_min = tval;
        }
        tval = parseFloat(Math.max(...tmp_upper_limits).toFixed(2));
        if (tmp_all.limits_max < tval) {
          tmp_all.limits_max = tval;
        }
      });
    }

    tmp_all.map = listData[0]?.map || '';

    const upperLimits = new Set(listData.map((item: {upper_limit: any}) => item.upper_limit));
    const lowerLimits = new Set(listData.map((item: {lower_limit: any}) => item.lower_limit));
    tmp_all.hasMultipleLimit = upperLimits.size > 1 || lowerLimits.size > 1;

    setGraphBaseData(tmp_all);
  }, [listData]);

  return {
    startProcess,
    t,
    classes,
    searchValue,
    tmpSearchValue,
    setTmpSearchValue,
    handleChange,
    autoOnChange,
    work,
    map,
    deviceid,
    autoCompleteReset,
    setAutoCompleteReset,
    doSearch,
    restSearch,
    setDetailListOpen,
    changeSearchType,
    listData,
    graphBaseData,
    zoomChartOpen,
    setZoomChartOpen,
    mySettings,
    detailListOpen,
    listDigClose,
    CloudDistanceDetailColumn,
    screenLock
  };
};
