import {Box, Grid, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import LFCSelectFormDeviceId from '_components-with-data/inputs/LFCSelectFormDeviceId';
import LFCSelectFormDongleId from '_components-with-data/inputs/LFCSelectFormDongleId';
import LFCSelectFormWork from '_components-with-data/inputs/LFCSelectFormWork';
import LFCNumberField from '_components/inputs/LFCNumberField';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTimePicker from '_components/inputs/LFCTimePicker';
import {handleChangeMultipleUtil, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import LFCSavedCondition, {ConditionParamer} from './LFCSavedCondition';
import {useTranslation} from 'react-i18next';

interface Cols {
  select_time: string;
  working_time: number;
  target_number: string;
  work: string[];
  deviceid: string[];
  dongleid: string[];
}

interface Props {
  values: Cols;
  onChange: (vals: Cols) => void;
  pageId: number;
  locationId: number;
}

/**
 * 抽出条件　進捗モニタ用(サイドメニュー表示用)
 * @param props
 * @returns
 */
const ProgressMonitor = (props: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [searchValue, setSearchValue] = useState<Cols>(props.values);

  const working_time = [...Array(24)].map((_, i) => {
    return {name: String(i + 1), value: i + 1};
  });

  useEffect(() => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    props.onChange(searchValue);
  }, [searchValue]);

  useEffect(() => {
    let dtWork: any =
      localStorage.getItem('work') !== ''
        ? localStorage.getItem('work')?.toString().split(',')
        : [t('すべて')];
    let dtDeviceid: any =
      localStorage.getItem('deviceid') !== ''
        ? localStorage.getItem('deviceid')?.toString().split(',')
        : [t('すべて')];
    let dtDongleid: any =
      localStorage.getItem('dongleid') !== ''
        ? localStorage.getItem('dongleid')?.toString().split(',')
        : [t('すべて')];

    setSearchValue(prevState => ({
      ...prevState,
      work: dtWork,
      deviceid: dtDeviceid,
      dongleid: dtDongleid
    }));

    props.onChange(searchValue);
  }, []);

  // 保存した検索条件画面の表示状態
  const [openSavedCondition, setOpenSavedCondition] = React.useState(false);
  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  // 保存した検索条件画面を開く
  const onLoadSavedCondition = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setCondition([
      {
        name: 'select_time',
        value: searchValue.select_time,
        valueLabel: searchValue.select_time,
        colName: t('稼働開始時刻'),
        colWidth: 100
      },
      {
        name: 'working_time',
        value: searchValue.working_time,
        valueLabel: String(searchValue.working_time),
        colName: t('1日の稼働時間(h)'),
        colWidth: 120
      },
      {
        name: 'target_number',
        value: searchValue.target_number,
        valueLabel: searchValue.target_number,
        colName: t('目標生産数'),
        colWidth: 100
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? t('すべて') : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel:
          searchValue.deviceid[0] === t('すべて') ? t('すべて') : searchValue.deviceid.join(','),
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'dongleid',
        value: searchValue.dongleid,
        valueLabel:
          searchValue.dongleid[0] === t('すべて') ? t('すべて') : searchValue.dongleid.join(','),
        colName: t('オペレータ'),
        colWidth: 300
      }
    ]);
    console.log('onLoadSavedCondition');
    setOpenSavedCondition(true);
  };

  // 保存した検索条件画面で検索条件が選択された
  const onSelectSavedCondition = (conditionValues: any) => {
    localStorage.setItem('work', conditionValues.work);
    localStorage.setItem('deviceid', conditionValues.deviceid);
    localStorage.setItem('dongleid', conditionValues.dongleid);
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <Box m={2} sx={{width: 240}}>
      <form ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} mb={1}>
            <Typography color="textSecondary" variant="subtitle1">
              {t('抽出条件')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LFCTimePicker
              name={'select_time'}
              label={t('稼働開始時刻')}
              value={searchValue.select_time}
              onChange={event => handleInputChange(event, searchValue, setSearchValue)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectForm
              name={'working_time'}
              label={t('1日の稼働時間(h)')}
              value={searchValue.working_time}
              id={'working_time'}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              selectItem={working_time}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCNumberField
              name={'target_number'}
              label={t('目標生産数')}
              value={searchValue.target_number}
              onChange={event => handleInputChange(event, searchValue, setSearchValue)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectFormWork
              name={t('work')}
              value={searchValue.work}
              onChange={event => handleChangeMultipleUtil(setSearchValue, searchValue, event, t)}
              multiple={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectFormDeviceId
              name={'deviceid'}
              value={searchValue.deviceid}
              onChange={event => handleChangeMultipleUtil(setSearchValue, searchValue, event, t)}
              multiple={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectFormDongleId
              name={'dongleid'}
              value={searchValue.dongleid}
              onChange={event => handleChangeMultipleUtil(setSearchValue, searchValue, event, t)}
              multiple={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={props.pageId}
              locationNo={props.locationId}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProgressMonitor;
