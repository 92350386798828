import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {round} from 'lodash';
import LFCSingleValues2 from 'pages/lq-job/_components/LFCSingleValues2';
import {useTranslation} from 'react-i18next';

import {WidthFull} from '@mui/icons-material';
import {Box, Card, Grid, Stack} from '@mui/material';
import {green, red} from '@mui/material/colors';

import {CloudDistanceListData} from '../../../types';

interface CloudDistanceSubPanelProps {
  selectedValue: string;
  listData: CloudDistanceListData[];
}

export const CloudDistanceSubPanel = ({selectedValue, listData}: CloudDistanceSubPanelProps) => {
  const {t} = useTranslation();
  return (
    <Stack spacing={2} sx={{width: '100%'}}>
      <LFCSingleValue
        title={t('検査数')}
        val={listData.filter((b: any) => b.map === selectedValue).length}
        titleVariant="caption"
        valVariant="body1"
        key={t('検査数')}
        fontColor={green[500]}
      />
      <Box style={{marginTop: '2px'}}>
        <Grid container>
          <Grid item xs={6}>
            <LFCSingleValue
              title={t('良品')}
              val={
                listData.filter((b: any) => b.map === selectedValue && b.total_judge === 0).length
              }
              titleVariant="caption"
              valVariant="body1"
              key={t('良品')}
              fontColor={green[500]}
            />
          </Grid>
          <Grid item xs={6}>
            <LFCSingleValue
              title={t('不良品')}
              val={
                listData.filter((b: any) => b.map === selectedValue && b.total_judge === 1).length
              }
              titleVariant="caption"
              valVariant="body1"
              key={t('不良品')}
              fontColor={red[500]}
            />
          </Grid>
        </Grid>
      </Box>
      <LFCSingleValue
        title={t('不良率')}
        val={
          listData.filter((b: any) => b.map === selectedValue && b.total_judge === 0).length === 0
            ? 0
            : (
                (100 *
                  listData.filter((b: any) => b.map === selectedValue && b.total_judge === 1)
                    .length) /
                listData.filter((b: any) => b.map === selectedValue).length
              ).toFixed(2)
        }
        titleVariant="caption"
        valVariant="body1"
        key={t('不良率')}
        fontColor={red[500]}
        unit={' %'}
      />
    </Stack>
  );
};
