import {useTranslation} from 'react-i18next';

import {Chip, FormControlLabel, Radio, RadioGroup, Stack, Typography} from '@mui/material';

interface CloudDistanceSearchType {
  id: number;
  text: string;
}

interface SearchTypeRadioBtnProps {
  value: number;
  onChange: (value: number) => void;
}

export const SearchTypeRadioBtn = (props: SearchTypeRadioBtnProps) => {
  const {t} = useTranslation();
  const searchType: CloudDistanceSearchType[] = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(Number(e.target.value));
  };

  return (
    <Stack direction="row" alignContent={'center'} alignItems={'center'} spacing={1}>
      <Chip label={t('集計単位')} />
      <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
        {searchType.map((item: any) => (
          <FormControlLabel
            value={item.id}
            key={'search_type-' + item.id}
            control={<Radio onChange={onChangeRadio} checked={item.id === props.value} />}
            label={
              <Typography display="block" key={'search_type-body-' + item.id}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};
