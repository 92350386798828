import LFCChartsBoxXYZ2 from '_components/charts/LFCChartsBoxXYZ2';
import {
  CloudDistanceGraphBaseData,
  CloudDistanceGraphSelectData,
  CloudDistanceSearchValue
} from 'pages/lq-job/cloud-distance/types';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface CloudDistanceGraphPanelProps {
  selectedValue: string;
  graphBaseData: CloudDistanceGraphBaseData;
  searchValue: CloudDistanceSearchValue;
  graphClick: (datas: CloudDistanceGraphSelectData) => void;
  zoomChartOpen: string;
  setZoomChartOpen: React.Dispatch<React.SetStateAction<string>>;
}

export const CloudDistanceGraphPanel = ({
  selectedValue,
  graphBaseData,
  searchValue,
  graphClick,
  zoomChartOpen,
  setZoomChartOpen
}: CloudDistanceGraphPanelProps) => {
  const {t} = useTranslation();

  const handleClick = useCallback(() => {
    graphClick({mapName: selectedValue});
  }, [graphClick, selectedValue]);

  const handleZoomCharts = useCallback(() => {
    setZoomChartOpen('zoom-chart-' + selectedValue);
  }, [setZoomChartOpen]);

  const handleZoomChartsClose = useCallback(() => {
    setZoomChartOpen('');
  }, [setZoomChartOpen]);

  const memoizedChart = useMemo(
    () => (
      <LFCChartsBoxXYZ2
        title={`${t('部位名')}:${selectedValue}`}
        source={graphBaseData}
        x={{dsColumn: '', type: 'category'}}
        y={{dsColumn: '', type: 'value'}}
        searchType={searchValue.searchType}
        exportFilename={`Cloud Distance_` + selectedValue}
        onClick={handleClick}
        height={'30vh'}
        top={'40'}
        bottom={'70'}
        mapName={selectedValue}
        isShowDetailAnalysis={true}
        onClickDetailAnalysis={handleClick}
        zoomChartId={'zoom-chart-' + selectedValue}
        zoomedChartId={zoomChartOpen}
        zoomCharts={handleZoomCharts}
        zoomChartsClose={handleZoomChartsClose}
      />
    ),
    [
      t,
      selectedValue,
      graphBaseData,
      searchValue.searchType,
      handleClick,
      zoomChartOpen,
      handleZoomCharts,
      handleZoomChartsClose
    ]
  );

  return memoizedChart;
};
