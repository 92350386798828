import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) => ({
  cardMenu: {
    backgroundColor: 'transparent',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  menuImage: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main
  }
}));

interface LFCMenuProps {
  path: string;
  name: string;
  exText: string;
}

/**
 * MENU Bodyコンポーネント
 * @param props
 * @returns
 */
const LFCMenu = (props: LFCMenuProps) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <Card className={classes.cardMenu}>
        <CardActionArea component={Link} to={`${props.path}`} style={{textDecoration: 'none'}}>
          <Box display="flex" justifyContent="center" p={1}>
            <CardMedia component="svg">
              <use xlinkHref={`images/menu${props.path}.svg#_x32_`} className={classes.menuImage} />
            </CardMedia>
          </Box>
          <CardContent
            component={Box}
            display="flex"
            style={{paddingTop: 0, paddingBottom: 0, height: '4.5rem'}}
          >
            <Typography variant="button">{t(`menu.page_name.${props.name}`)}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default LFCMenu;
