import LFCChartsBarWorkCloudDistance2 from '_components/charts/LFCChartsBarWorkCloudDistance2';
import LFCChartsBoxXYZ8 from '_components/charts/LFCChartsBoxXYZ8';
import LFCChartsBoxXYZ9 from '_components/charts/LFCChartsBoxXYZ9';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';

import {
    Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';

import { CloudDistanceDigGraphBaseData, CloudDistanceDigSearchValue } from '../types';

interface CloudDistanceMinAnalysisProps {
  graphBaseData: CloudDistanceDigGraphBaseData;
  searchValue: CloudDistanceDigSearchValue;
  zoomChartOpen: any;
  setZoomChartOpen: any;
  classes: any;
  t: TFunction<'translation', undefined, 'translation'>;
  listData: any;
  mySettings: any;
}

export const CloudDistanceMinAnalysis = ({
  graphBaseData,
  searchValue,
  zoomChartOpen,
  setZoomChartOpen,
  classes,
  t,
  listData,
  mySettings
}: CloudDistanceMinAnalysisProps) => {
  const memoizedContent = useMemo(() => {
    const filteredData = listData
      .map((b: any) => b.lower_distance)
      .flat()
      .filter((value: any) => value.length !== 0);

    const maxUpperDistance = Math.max(...filteredData);
    const minUpperDistance = Math.min(...filteredData);

    const averageUpperDistance =
      filteredData.reduce((sum: number, value: number) => sum + value, 0) / filteredData.length;

    const medianUpperDistance = (() => {
      const sorted = [...filteredData].sort((a, b) => a - b);
      const middle = Math.floor(sorted.length / 2);
      return sorted.length % 2 !== 0 ? sorted[middle] : (sorted[middle - 1] + sorted[middle]) / 2;
    })();
    const variance =
      filteredData.reduce(
        (sum: number, value: number) => sum + Math.pow(value - averageUpperDistance, 2),
        0
      ) / filteredData.length;
    const standardDeviation = Math.sqrt(variance);

    const averagePlus3SD = averageUpperDistance + 3 * standardDeviation;
    const averageMinus3SD = averageUpperDistance - 3 * standardDeviation;
    const upperLimit = listData.filter((a: any) => a.map === searchValue.map)[0]?.upper_limit;
    const lowerLimit = listData.filter((a: any) => a.map === searchValue.map)[0]?.lower_limit;

    const cp = (upperLimit - lowerLimit) / (6 * standardDeviation);

    const val1 = Math.abs(upperLimit - averageUpperDistance) / (3 * standardDeviation);
    const val2 = Math.abs(averageUpperDistance - lowerLimit) / (3 * standardDeviation);
    const cpk = Math.min(val1, val2);

    let xLineMax = 0;
    let xRenge = 0;

    const tmp_n: number = filteredData.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }
    xLineMax = upperLimit;

    const tmp_h: number = (upperLimit - lowerLimit) / tmp_k;
    const tmp_dot: number = String(tmp_h).indexOf('.');
    if (tmp_dot > 0) {
      xRenge = Math.floor(tmp_h * (tmp_dot * 100)) / (tmp_dot * 100);
    } else {
      xRenge = Math.floor(tmp_h);
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{fontWeight: 'bold'}}>{t('最小値分析')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <LFCChartsBoxXYZ8
            title={t('最小値 推移')}
            source={graphBaseData}
            x={{dsColumn: '', type: 'category'}}
            y={{dsColumn: '', type: 'value'}}
            exportFilename={`Cloud Distance Min`}
            height={'30vh'}
            top={'40'}
            bottom={'70'}
            zoomChartId={'zoom-chart-5'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-5')}
            zoomChartsClose={() => setZoomChartOpen('')}
            averageUpperDistance={averageUpperDistance}
            medianUpperDistance={medianUpperDistance}
            averagePlus3SD={averagePlus3SD}
            averageMinus3SD={averageMinus3SD}
            searchType={searchValue.searchType}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LFCChartsBarWorkCloudDistance2
            title={`${t('最小値 分布')}`}
            source={graphBaseData}
            exportFilename={`CloudDistance_${t('最小値ばらつき')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            top={'60'}
            bottom={'70'}
            onClick={() => {}}
            zoomChartId={'zoom-chart-6'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-6')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMax={xLineMax}
            xRenge={xRenge}
            averageMinus3SD={averageMinus3SD}
            averagePlus3SD={averagePlus3SD}
            averageUpperDistance={averageUpperDistance}
            medianUpperDistance={medianUpperDistance}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LFCChartsBoxXYZ9
            title={t('最小値　ばらつき推移')}
            source={graphBaseData}
            ldata={listData}
            exportFilename={`CloudDistance_${t('最小値ばらつき')}`}
            zoomChartId={'zoom-chart-7'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-7')}
            zoomChartsClose={() => setZoomChartOpen('')}
            searchValue={searchValue}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {Number(
                      listData.filter((a: any) => a.map === searchValue.map)[0]?.upper_limit
                    ).toLocaleString()}
                    mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {Number(maxUpperDistance).toLocaleString()} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">{Number(averagePlus3SD).toLocaleString()} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {Number(cp).toLocaleString()}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    -
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {Number(averageUpperDistance).toLocaleString()} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {Number(standardDeviation).toLocaleString()} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {Number(medianUpperDistance).toLocaleString()} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {Number(cpk).toLocaleString()}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {Number(
                      listData.filter((a: any) => a.map === searchValue.map)[0]?.lower_limit
                    ).toLocaleString()}
                    mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {Number(minUpperDistance).toLocaleString()} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">
                    {Number(averageMinus3SD).toLocaleString()} mm
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography align="center">
            {listData.length > 0 &&
            listData.filter((b: any) => b.map === searchValue.map)?.[0]?.hasMultipleLimit
              ? '※規格複数のため最新値'
              : ''}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    graphBaseData,
    searchValue,
    zoomChartOpen,
    setZoomChartOpen,
    classes,
    t,
    listData,
    mySettings
  ]);

  return memoizedContent;
};
