import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Checkbox} from '@mui/material';
import {GridColumns, GridRenderCellParams, useGridApiRef} from '@mui/x-data-grid-pro';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import awsConfiguration from 'awsConfiguration';
interface AlertItem {
  id: number;
  isActive: boolean;
  alert_name: string;
  monitoring_device: string;
  monitoring_item: string;
  judgement_rule: string;
  monitoring_term: string;
  assessment_frequency: string;
}

export const useAlertSettings = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const {enqueueSnackbar} = useSnackbar();

  // CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [dgRows, setDgRows] = useState<AlertItem[]>([]);
  const apiRef = useGridApiRef();
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const [isAlertHistoryDlgOpen, setIsAlertHistoryDlgOpen] = useState<boolean>(false);
  const [isAlertSettingDlgOpen, setIsAlertSettingDlgOpen] = useState<boolean>(false);
  const [alertId, setAlertId] = useState<string>('');
  const dayMapping = {
    Mon: '月',
    Tue: '火',
    Wed: '水',
    Thur: '木',
    Fri: '金',
    Sat: '土',
    Sun: '日'
  };

  // 英語の曜日文字列を日本語に変換する関数
  const convertDaysToJapanese = (input: string) => {
    // 入力文字列を整形して配列に変換
    const daysArray = input
      .replace(/[()']/g, '') // 不要な括弧やシングルクォートを削除
      .replace(/\.\s*/g, ',') // 誤って `Thur'.` のような表記に対応
      .split(',') // カンマで分割
      .map(day => day.trim()); // 前後の空白を削除

    // 日本語に変換
    return daysArray
      .map(day => `${t((dayMapping as any)[day as any] + '曜')}`.replace('曜', '') || '')
      .join(''); // 結合して文字列として返す
  };

  const reload = async () => {
    try {
      setStartProcess(true);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 310,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        setDgRows(
          ds
            .filter((e: {[x: string]: number}) => e['Active?'] === 1 || e['Active?'] === 0)
            .map((e: any) => {
              return {
                ...e,
                id: e.alert_id,
                isActive: e['Active?'] === 1 ? true : false,
                details: e.alert_id,
                monitoring_term: {
                  days: e.schedule,
                  from: e.From,
                  to: e.To
                }
              };
            })
            .sort((a: any, b: any) => a.alert_id - b.alert_id)
        );
      });
    } catch (error) {
      console.error('An error occurred in the reload function:', error);
    } finally {
      setStartProcess(false);
    }
  };

  /**
   * 監視アイテムをロードします。
   * @returns void
   */
  const init = async () => {
    try {
      setStartProcess(true);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 310,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        setDgRows(
          ds
            .filter((e: {[x: string]: number}) => e['Active?'] === 1 || e['Active?'] === 0)
            .map((e: any) => {
              return {
                ...e,
                id: e.alert_id,
                isActive: e['Active?'] === 1 ? true : false,
                details: e.alert_id,
                monitoring_term: {
                  days: e.schedule,
                  from: e.From,
                  to: e.To
                }
              };
            })
            .sort((a: any, b: any) => a.alert_id - b.alert_id)
        );
      });

      const ds = await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 40002,
        parameters: {},
        cancelToken: source.token,
        t
      });

      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDevices(tmp);

      await adminAPIAccess({
        apipath: 'GetUserList',
        parameters: {
          user_pool_id: awsConfiguration.UserPoolId
        },
        cancelToken: source.token
      }).then(response => {
        const tmp = response.data.datas.map((item: any) => {
          return {email: item['email'], id: item['user_id']};
        });
        setEmails(tmp);
      });

      setStartProcess(false);
    } catch (error) {
      console.error('An error occurred in the init function:', error);
    } finally {
      setStartProcess(false);
    }
  };

  const dgColumns: GridColumns = [
    {field: 'id', headerName: 'No', description: '', hide: true},
    {
      field: 'details',
      headerName: '',
      width: 100,
      renderCell: prms => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openAlertSettingDialog(prms.value)}
        >
          {t('詳細')}
        </Button>
      ),
      sortable: false,
      filterable: false
    },
    {
      field: 'isActive?',
      headerName: `${t('有効')}`,
      width: 80,
      renderCell: params => (params.row.isActive ? <Checkbox checked disableRipple /> : <></>)
    },
    {
      field: 'alert_name',
      headerName: `${t('アラート名')}`,
      description: `${t('アラート名')}`,
      width: 400
    },
    {
      field: 'machine',
      headerName: `${t('監視装置')}`,
      description: `${t('監視装置')}`,
      width: 200
    },
    {
      field: 'process_type',
      headerName: `${t('監視アイテム')}`,
      description: `${t('監視アイテム')}`,
      width: 350,
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 2) {
          return t('欠点率（本日分）：不良部位数/検査部位数');
        }
        if (prms.value === 1) {
          return t('不良率 （本日分）：不良製品数/検査製品数');
        }
        return 'undefined';
      }
    },
    {
      field: 'Rule',
      headerName: `${t('判定ルール')}`,
      description: `${t('判定ルール')}`,
      width: 150,
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 1) {
          return t('3σ異常判定（最新結果と直近30日間の比較）');
        }
        return 'undefined';
      }
    },
    {
      field: 'monitoring_term',
      headerName: `${t('監視期間')}`,
      description: `${t('監視期間')}`,
      width: 350,
      renderCell: (prms: GridRenderCellParams) => {
        return prms.value.days === '{}'
          ? `${prms.value.from.split(':').slice(0, 2).join(':')} ~ ${prms.value.to
              .split(':')
              .slice(0, 2)
              .join(':')}`
          : `${convertDaysToJapanese(prms.value.days)} : ${prms.value.from
              .split(':')
              .slice(0, 2)
              .join(':')} ~ ${prms.value.to.split(':').slice(0, 2).join(':')}`;
      }
    },
    {
      field: 'alert_interval',
      headerName: `${t('判定頻度')}`,
      description: `${t('判定頻度')}`,
      width: 150,
      renderCell: (prms: GridRenderCellParams) => {
        return `${prms.value} ${t('時間毎')}`;
      }
    }
  ];

  useEffect(() => {
    init();
  }, []);

  const openAlertHistoryDialog = () => {
    setIsAlertHistoryDlgOpen(true);
  };

  const closeAlertHistoryDialog = () => {
    setIsAlertHistoryDlgOpen(false);
  };

  const openAlertSettingDialog = (alertId: string) => {
    setAlertId(alertId);
    setIsAlertSettingDlgOpen(true);
  };

  const closeAlertSettingDialog = () => {
    setIsAlertSettingDlgOpen(false);
  };

  const [devices, setDevices] = useState<{label: string}[]>([]);
  const [emails, setEmails] = useState<{email: string; id: string}[]>([]);

  return {
    t,
    formRef,
    dgRows,
    apiRef,
    startProcess,
    openAlertHistoryDialog,
    closeAlertHistoryDialog,
    openAlertSettingDialog,
    closeAlertSettingDialog,
    dgColumns,
    isAlertHistoryDlgOpen,
    isAlertSettingDlgOpen,
    alertId,
    devices,
    emails,
    reload
  };
};
