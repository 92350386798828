import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import dayjs from 'dayjs';
import {isEmpty, max} from 'lodash';
import {CloudDistanceGraphBaseData} from 'pages/lq-job/cloud-distance/types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {blue, red} from '@mui/material/colors';

import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';

/**
 * 引数
 */
interface Props {
  title: string;
  source: CloudDistanceGraphBaseData;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  isShowDetailAnalysis?: boolean;
  onClickDetailAnalysis?: any;
  zoomStatus?: boolean;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  searchType: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ2 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);
  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp_upper_distance: any = [];
    let tmp_lower_distance: any = [];
    let tmpOutPut: any = [];
    tmpOutPut[0] = [];
    tmpOutPut[1] = [];
    tmpOutPut[2] = [];

    if (!isEmpty(props.source.xlist)) {
      props.source.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp_upper_distance.push(props.source.data_upper_distance[index]);
              tmp_lower_distance.push(props.source.data_lower_distance[index]);
            }
          });
        } else {
          tmp_upper_distance.push([]);
          tmp_lower_distance.push([]);
        }
      });

      tmp_upper_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[0].push(CalcQuartile(a));
        } else {
          tmpOutPut[0].push([]);
        }
      });
      tmp_lower_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[1].push(CalcQuartile(a));
        } else {
          tmpOutPut[1].push([]);
        }
      });
    } else {
      props.source.xlist.find((b: string, index: number) => {
        tmpOutPut[0].push(CalcQuartile([props.source.data_upper_distance[index]]));
        tmpOutPut[1].push(CalcQuartile([props.source.data_lower_distance[index]]));
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportFields, setExportFields] = useState({});
  const [exportData, setExportData] = useState<any>([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    if (!props.source.xlist) return;

    if (props.searchType === 0) {
      //検査ごと
      setExportFields({
        serial: t('シリアルナンバー'),
        for_export: t('検査日時'),
        map: t('検査部位'),
        data_upper_distance: t('最大値'),
        data_lower_distance: t('最小値')
      });

      const _exportData = props.source.xlist.map((xval: string, i: number) => {
        return {
          serial: props.source.detail[i].serial || props.source.detail[i].uuid,
          for_export: fmtYYYYMMDDHHmmss(props.source.detail[i].lq_time),
          map: props.source.map,
          data_upper_distance: props.source.data_upper_distance[i],
          data_lower_distance: props.source.data_lower_distance[i]
        };
      });
      setExportData(_exportData);
    } else {
      setExportFields({
        for_export: t('検査日時'),
        map: t('検査部位'),
        x0: `${t('X座標誤差')} ${t('最大')}`,
        x1: `${t('X座標誤差')} ${t('Q3')}`,
        x2: `${t('X座標誤差')} ${t('中央(orQ2)')}`,
        x3: `${t('X座標誤差')} ${t('Q1')}`,
        x4: `${t('X座標誤差')} ${t('最小')}`,
        y0: `${t('Y座標誤差')} ${t('最大')}`,
        y1: `${t('Y座標誤差')} ${t('Q3')}`,
        y2: `${t('Y座標誤差')} ${t('中央(orQ2)')}`,
        y3: `${t('Y座標誤差')} ${t('Q1')}`,
        y4: `${t('Y座標誤差')} ${t('最小')}`
      });

      if (boxData.length === 0) return;

      const _exportData = props.source.xlist
        .map((xval: string, i: number) => {
          if (!Array.isArray(boxData[0][i])) return null;
          if ((boxData[0][i] as any[]).length === 0) return null;

          return {
            for_export: fmtYYYYMMDDHHmmss(xval),
            map: props.source.map,
            x0: boxData[0][i][4],
            x1: boxData[0][i][3],
            x2: boxData[0][i][2],
            x3: boxData[0][i][1],
            x4: boxData[0][i][0],
            y0: boxData[1][i][4],
            y1: boxData[1][i][3],
            y2: boxData[1][i][2],
            y3: boxData[1][i][1],
            y4: boxData[1][i][0]
          };
        })
        .filter((item: any) => item !== null); // nullの行を除外
      setExportData(_exportData);
    }
  }, [boxData, props.searchType]);
  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: '90%',
            textStyle: {
              fontSize: 10
            },
            data: [
              {
                name: t('最大値')
              },
              {
                name: t('最小値')
              }
            ]
          },
          tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
              if (params.seriesType === 'boxplot') {
                // [min,  Q1,  median (or Q2),  Q3,  max]
                return (
                  params.name +
                  ':' +
                  params.seriesName +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  Number(params.value[5]).toFixed(2) +
                  '<br/>' +
                  'Q3：' +
                  Number(params.value[4]).toFixed(2) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  Number(params.value[3]).toFixed(2) +
                  '<br/>' +
                  'Q1：' +
                  Number(params.value[2]).toFixed(2) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  Number(params.value[1]).toFixed(2) +
                  `
                  <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1; position: absolute; right: 5; bottom: 5;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#4B4B4B;}
</style>
<g>
	<path class="st0" d="M464.085,276.875c-20.078-25.1-41.523-46.713-41.523-46.713c-5.891-6.609-14.246-10.516-23.092-10.769
		c-8.862-0.262-17.436,3.131-23.699,9.393l-35.464,35.481c-5.865,5.848-15.368,5.848-21.218,0l-20.956-20.948
		c-2.506-2.515-6.574-2.515-9.098,0l-45.718,45.726c-2.507,2.507-2.515,6.584,0,9.098l20.947,20.947
		c5.849,5.857,5.849,15.352,0,21.218l-35.481,35.472c-6.253,6.262-9.671,14.828-9.41,23.69c0.261,8.853,4.169,17.208,10.794,23.091
		c0,0,21.606,21.436,46.705,41.523c53.483,42.79,136.403,72.21,207.271,20.054C536.295,413.27,506.866,330.366,464.085,276.875z" style="fill: rgb(75, 75, 75);"></path>
	<path class="st0" d="M267.22,227.098L164.323,124.2l8.499-45.709c3.199-17.167-2.27-34.789-14.618-47.144l-23.2-23.201
		c-2.676-2.683-5.849-4.752-9.2-6.11c-5.047-2.043-10.491-2.541-15.731-1.528c-5.224,0.996-10.339,3.604-14.348,7.63L8.129,95.725
		c-2.676,2.667-4.743,5.84-6.094,9.2c-2.042,5.046-2.54,10.481-1.527,15.723c0.996,5.233,3.604,10.339,7.63,14.356l23.2,23.21
		C43.694,170.552,61.325,176.02,78.5,172.83l45.701-8.507L227.097,267.22L267.22,227.098z M131.923,70.878l-9.579,51.466
		l-51.465,9.588c-3.688,0.691-7.469-0.49-10.119-3.14l-13.428-13.436l68.024-68.024l13.436,13.436
		C131.433,63.418,132.615,67.199,131.923,70.878z" style="fill: rgb(75, 75, 75);"></path>
</g>
</svg>
                  `
                );
              } else if (params.seriesType === 'scatter') {
                return (
                  params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
                );
              } else if (params.seriesType === 'line') {
                return (
                  t('連番') +
                  ':' +
                  params.name +
                  '<br/>' +
                  t('シリアルナンバー') +
                  ':' +
                  (props.source.detail[params.dataIndex].serial ||
                    props.source.detail[params.dataIndex].uuid) +
                  '<br/>' +
                  '検査日時：' +
                  dayjs(props.source.detail[params.dataIndex].lq_time).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ) +
                  '<br/>' +
                  (params.seriesName === '最大値' ? t('最大値') : t('最小値')) +
                  ':' +
                  Number(params.value).toFixed(2) +
                  `
                  <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1; position: absolute; right: 5; bottom: 5;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#4B4B4B;}
</style>
<g>
	<path class="st0" d="M464.085,276.875c-20.078-25.1-41.523-46.713-41.523-46.713c-5.891-6.609-14.246-10.516-23.092-10.769
		c-8.862-0.262-17.436,3.131-23.699,9.393l-35.464,35.481c-5.865,5.848-15.368,5.848-21.218,0l-20.956-20.948
		c-2.506-2.515-6.574-2.515-9.098,0l-45.718,45.726c-2.507,2.507-2.515,6.584,0,9.098l20.947,20.947
		c5.849,5.857,5.849,15.352,0,21.218l-35.481,35.472c-6.253,6.262-9.671,14.828-9.41,23.69c0.261,8.853,4.169,17.208,10.794,23.091
		c0,0,21.606,21.436,46.705,41.523c53.483,42.79,136.403,72.21,207.271,20.054C536.295,413.27,506.866,330.366,464.085,276.875z" style="fill: rgb(75, 75, 75);"></path>
	<path class="st0" d="M267.22,227.098L164.323,124.2l8.499-45.709c3.199-17.167-2.27-34.789-14.618-47.144l-23.2-23.201
		c-2.676-2.683-5.849-4.752-9.2-6.11c-5.047-2.043-10.491-2.541-15.731-1.528c-5.224,0.996-10.339,3.604-14.348,7.63L8.129,95.725
		c-2.676,2.667-4.743,5.84-6.094,9.2c-2.042,5.046-2.54,10.481-1.527,15.723c0.996,5.233,3.604,10.339,7.63,14.356l23.2,23.21
		C43.694,170.552,61.325,176.02,78.5,172.83l45.701-8.507L227.097,267.22L267.22,227.098z M131.923,70.878l-9.579,51.466
		l-51.465,9.588c-3.688,0.691-7.469-0.49-10.119-3.14l-13.428-13.436l68.024-68.024l13.436,13.436
		C131.433,63.418,132.615,67.199,131.923,70.878z" style="fill: rgb(75, 75, 75);"></path>
</g>
</svg>
                  `
                );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '75',
            right: '5',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            splitLine: {
              show: true
            },
            axisLabel: {
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series:
            props.searchType === 0
              ? [
                  {
                    name: t('最大値'),
                    type: 'line',
                    data: props.source.data_upper_distance || 0,
                    color: red[500]
                  },
                  {
                    name: t('最小値'),
                    type: 'line',
                    data: props.source.data_lower_distance || 0,
                    color: blue[500]
                  }
                ]
              : [
                  {
                    name: t('最大値'),
                    type: 'boxplot',
                    data: boxData[0],
                    color: red[500]
                  },
                  {
                    name: t('最小値'),
                    type: 'boxplot',
                    data: boxData[1],
                    color: blue[500]
                  }
                ]
        }}
        exportData={exportData}
        exportFields={exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
        isShowDetailAnalysis={props.isShowDetailAnalysis ?? false}
        onClickDetailAnalysis={props.onClickDetailAnalysis ?? undefined}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxXYZ2;
