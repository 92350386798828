import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Dialog, DialogContent} from '@mui/material';
import {GridColDef, GridRenderCellParams, GridValueFormatterParams} from '@mui/x-data-grid-pro';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {fmtYYYYMMDDHHmm} from '_logics/LFCFormatUtil';

import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import {useSnackbar} from 'notistack';
import axios from 'axios';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AlertHistoryDialog = ({open, onClose}: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  /**
   * 初期カラム定義
   */
  const dgColumns: GridColDef[] = [
    {
      field: 'time_sent',
      headerName: `${t('監視日時')}`,
      description: `${t('監視日時')}`,
      align: 'center',
      width: 200,
      valueFormatter: (prms: GridValueFormatterParams) => fmtYYYYMMDDHHmm(prms.value)
    },
    {
      field: 'alert_name',
      headerName: `${t('アラート名')}`,
      description: `${t('アラート名')}`,
      align: 'center',
      width: 200
    },
    {
      field: 'machine',
      headerName: `${t('監視設備')}`,
      description: `${t('監視設備')}`,
      align: 'center',
      width: 150
    },
    {
      field: 'process_type',
      headerName: `${t('監視アイテム')}`,
      description: `${t('監視アイテム')}`,
      align: 'center',
      width: 400,
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 2) {
          return t('欠点率（本日分）：不良部位数/検査部位数');
        }
        if (prms.value === 1) {
          return t('不良率 （本日分）：不良製品数/検査製品数');
        }
        return 'undefined';
      }
    },
    {
      field: 'Rule',
      headerName: `${t('判定ルール')}`,
      description: `${t('判定ルール')}`,
      align: 'center',
      width: 80,
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 1) {
          return t('3σ異常判定（最新結果と直近30日間の比較）');
        }
        return 'undefined';
      }
    },
    {
      field: 'status',
      headerName: `${t('判定結果')}`,
      description: `${t('判定結果')}`,
      width: 80,
      align: 'center',
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 1) {
          return t('安定');
        }
        if (prms.value === 2) {
          return t('不安定');
        }
        if (prms.value === 3) {
          return t('警告');
        }
        if (prms.value === 4) {
          return t('危険');
        }
        return 'undefined';
      }
    },
    {
      field: 'reason',
      headerName: `${t('判定理由')}`,
      description: `${t('判定理由')}`,
      align: 'center',
      width: 100,
      renderCell: (prms: GridRenderCellParams) => {
        if (prms.value === 1) {
          return t('1σ以下');
        }
        if (prms.value === 2) {
          return t('１σ～２σ間');
        }
        if (prms.value === 3) {
          return t('２σ～UCL間');
        }
        if (prms.value === 4) {
          return t('UCL超え');
        }
        return 'undefined';
      }
    }
  ];

  /**
   * 検索条件初期値
   */

  const [searchValue, setSearchValue] = useState({
    date01: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
    date02: dayjs().format('YYYY-MM-DDTHH:mm')
  });

  const [dgRows, setDgRows] = useState<any>([]);

  const {enqueueSnackbar} = useSnackbar();

  // CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * 検索処理
   */
  const doSearch = () => {
    setStartProcess(true);
    setDgRows([]);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 309,
      parameters: {
        date01: searchValue.date01,
        date02: searchValue.date02
      },
      cancelToken: source.token,
      t
    }).then(ds => {
      setDgRows(
        ds.map((e: any, index: number) => {
          return {
            ...e,
            id: index
          };
        })
      );
    });
    setStartProcess(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth>
      <LFCDialogTitle onClose={onClose}>{t('通知履歴')}</LFCDialogTitle>
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <form ref={formRef}>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name="date01"
              label={t('日付From')}
              value={searchValue.date01}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
              required
            />
            <LFCDatetimePicker
              name="date02"
              label={t('日付To')}
              value={searchValue.date02}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
              required
            />
            <LFCButton color="primary" onClick={doSearch}>
              {t('検索')}
            </LFCButton>
          </LFCFormRowGroup>
        </form>
        <LFCDataGrid
          columns={dgColumns}
          rows={dgRows}
          height="80vh"
          exportFilename={`${t('通知履歴')}`}
          style={{cursor: 'pointer'}}
          pageSize={500}
          columnOrder={[{name: 'row_num', order: 0}]}
        />
      </DialogContent>
    </Dialog>
  );
};
