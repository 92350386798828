import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import ProgressBar from '_components/ProgressBar';
import {useMemo} from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import {Box, Button, Dialog, DialogContent, Divider} from '@mui/material';

import {CloudDistanceGraphSelectData, CloudDistanceSearchValue} from '../../types';
import {CloudDistanceDigSearchPanel} from './components/CloudDistanceDigSearchPanel';
import {CloudDistanceMaxAnalysis} from './components/CloudDistanceMaxAnalysis';
import {CloudDistanceMaxMinComparison} from './components/CloudDistanceMaxMinComparison';
import {CloudDistanceMinAnalysis} from './components/CloudDistanceMinAnalysis';
import {useCloudDistanceDig} from './hooks/useCloudDistanceDig';

/**
 * 引数
 */
interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: CloudDistanceGraphSelectData;
  searchValue: CloudDistanceSearchValue;
}

export const CloudDistanceDig = (props: Props) => {
  const {
    startProcess,
    t,
    classes,
    searchValue,
    tmpSearchValue,
    setTmpSearchValue,
    handleChange,
    autoOnChange,
    work,
    deviceid,
    autoCompleteReset,
    setAutoCompleteReset,
    doSearch,
    restSearch,
    setDetailListOpen,
    changeSearchType,
    listData,
    graphBaseData,
    zoomChartOpen,
    setZoomChartOpen,
    mySettings,
    detailListOpen,
    listDigClose,
    CloudDistanceDetailColumn,
    map,
    screenLock
  } = useCloudDistanceDig(props);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <LFCDialogTitle onClose={props.onClose}>{props.pageName}</LFCDialogTitle>
      <DialogContent>
        <ProgressBar startProcess={startProcess} screenLock={screenLock} />
        <CloudDistanceDigSearchPanel
          t={t}
          searchValue={tmpSearchValue}
          setSearchValue={setTmpSearchValue}
          handleChange={handleChange}
          work={work}
          map={map}
          deviceid={deviceid}
          autoOnChange={autoOnChange}
          autoCompleteReset={autoCompleteReset}
          setAutoCompleteReset={setAutoCompleteReset}
          doSearch={doSearch}
          restSearch={restSearch}
          setDetailListOpen={setDetailListOpen}
          changeSearchType={changeSearchType}
        />
        <Divider sx={{margin: '0.5em 0'}} />
        <Box style={{float: 'right'}}>
          <Button
            variant="contained"
            size={'small'}
            color="secondary"
            startIcon={<DescriptionIcon />}
            onClick={() => setDetailListOpen(true)}
          >
            {t('データ一覧')}
          </Button>
        </Box>
        {/* 最大値最小値比較 */}
        <CloudDistanceMaxMinComparison
          t={t}
          searchValue={searchValue}
          graphBaseData={graphBaseData}
          listData={listData}
          setZoomChartOpen={setZoomChartOpen}
          zoomChartOpen={zoomChartOpen}
        />
        <Divider sx={{margin: '0.5em 0'}} />

        {/* 最大値分析 */}
        <CloudDistanceMaxAnalysis
          t={t}
          classes={classes}
          searchValue={searchValue}
          graphBaseData={graphBaseData}
          listData={listData}
          setZoomChartOpen={setZoomChartOpen}
          zoomChartOpen={zoomChartOpen}
          mySettings={mySettings}
        />
        <Divider sx={{margin: '0.5em 0'}} />

        {/* 最小値分析 */}
        <CloudDistanceMinAnalysis
          t={t}
          classes={classes}
          searchValue={searchValue}
          graphBaseData={graphBaseData}
          listData={listData}
          setZoomChartOpen={setZoomChartOpen}
          zoomChartOpen={zoomChartOpen}
          mySettings={mySettings}
        />
        <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
          <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Cloud Distance')}：${t(
            '詳細データ'
          )}`}</LFCDialogTitle>
          <DialogContent>
            <LFCDataGridSimple
              columns={CloudDistanceDetailColumn}
              rows={listData}
              height="80vh"
              exportFilename={`Cloud Distance_${t('詳細データ')}`}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};
