import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import * as echarts from 'echarts';
import {CloudDistanceDigGraphBaseData} from 'pages/lq-job/cloud-distance/components/cloud-distance-dig/types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useTheme} from '@mui/material';

/**
 * 引数
 */
interface Props {
  title: string;
  source: CloudDistanceDigGraphBaseData;
  ldata: any;
  yAxisName?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  exportFilename?: string;
  height?: string;
  onClick?: (prm: {data: any}) => void;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  searchValue?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ7 = (props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const legendChange = (data: any) => {
    let graph: any = document.getElementById(
      props.zoomChartId !== undefined ? props.zoomChartId : 'graph'
    );
    let graphZoom: any = document.getElementById('graphZoom');
    let echartsInstance: any;

    if (!props.zoomStatus && graphZoom === null) {
      echartsInstance = echarts.getInstanceByDom(graph.firstChild);
    } else {
      echartsInstance = echarts.getInstanceByDom(graphZoom.firstChild);
    }

    switch (data.name) {
      case '移動範囲Rm':
        if (data.selected.移動範囲Rm) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: t('移動範囲Rs')
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: t('移動範囲Rm')
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('範囲')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cpk'
        });
        break;
      case '標準偏差':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('範囲')
        });
        if (data.selected.標準偏差) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: t('標準偏差')
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: t('標準偏差')
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cpk'
        });
        break;
      case '範囲':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        if (data.selected.範囲) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: t('範囲')
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: t('範囲')
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('標準偏差')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: 'Cpk'
        });
        break;
      case 'Cp':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('標準偏差')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('範囲')
        });
        if (data.selected.Cp) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: 'Cp'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: 'Cpk'
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendSelect',
          name: 'Cpk'
        });
        break;
      case 'Cpk':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('標準偏差')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('範囲')
        });
        echartsInstance.dispatchAction({
          type: 'legendSelect',
          name: 'Cp'
        });
        if (data.selected.Cpk) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: 'Cpk'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: 'Cpk'
          });
        }
        break;
    }
  };

  let legendSelected: any = {
    移動範囲Rm: true,
    範囲: props.searchValue.searchType === 0 ? true : false,
    標準偏差: true,
    Cp: false,
    Cpk: false
  };

  const searchKey =
    props.searchValue.searchType === 0
      ? 'idx'
      : props.searchValue.searchType === 1
        ? 'year_month_day_hour'
        : props.searchValue.searchType === 2
          ? 'year_month_day'
          : 'year_month';

  const standardDeviation = props?.source.xlist?.map((date: any) => {
    const matchedItems = props?.ldata?.filter((item: any) => item[searchKey] === date);
    if (matchedItems.length === 0) return undefined;

    if (matchedItems.length === 1) return matchedItems[0].upper_distance;

    const mean =
      matchedItems.reduce((acc: any, item: {upper_distance: any}) => acc + item.upper_distance, 0) /
      matchedItems.length;
    const variance =
      matchedItems.reduce(
        (acc: number, item: {upper_distance: number}) =>
          acc + Math.pow(item.upper_distance - mean, 2),
        0
      ) / matchedItems.length;
    return Math.sqrt(variance);
  });

  const cpValue = props?.source.xlist?.map((date: any) => {
    const matchedItems = props?.ldata?.filter((item: any) => item[searchKey] === date);
    if (matchedItems.length === 0) return undefined;

    const upperLimit = matchedItems[0].upper_limit;
    const lowerLimit = matchedItems[0].lower_limit;

    if (matchedItems.length === 1) {
      const standardDeviation = matchedItems[0].upper_distance;
      return (upperLimit - lowerLimit) / (6 * standardDeviation);
    }

    const mean =
      matchedItems.reduce((acc: any, item: {upper_distance: any}) => acc + item.upper_distance, 0) /
      matchedItems.length;
    const variance =
      matchedItems.reduce(
        (acc: number, item: {upper_distance: number}) =>
          acc + Math.pow(item.upper_distance - mean, 2),
        0
      ) / matchedItems.length;
    const standardDeviation = Math.sqrt(variance);

    return (upperLimit - lowerLimit) / (6 * standardDeviation);
  });
  const cpkValue = props?.source.xlist?.map((date: any) => {
    const matchedItems = props?.ldata?.filter((item: any) => item[searchKey] === date);
    if (matchedItems.length === 0) return undefined;

    const upperLimit = matchedItems[0].upper_limit;
    const lowerLimit = matchedItems[0].lower_limit;

    if (matchedItems.length === 1) {
      const mean = matchedItems[0].upper_distance;
      const standardDeviation = mean; // 1つのデータポイントでは標準偏差がその値と同じ
      const value1 = Math.abs(upperLimit - mean) / (3 * standardDeviation);
      const value2 = Math.abs(mean - lowerLimit) / (3 * standardDeviation);
      return Math.min(value1, value2);
    }

    const mean =
      matchedItems.reduce((acc: any, item: {upper_distance: any}) => acc + item.upper_distance, 0) /
      matchedItems.length;
    const variance =
      matchedItems.reduce(
        (acc: number, item: {upper_distance: number}) =>
          acc + Math.pow(item.upper_distance - mean, 2),
        0
      ) / matchedItems.length;
    const standardDeviation = Math.sqrt(variance);

    const value1 = Math.abs(upperLimit - mean) / (3 * standardDeviation);
    const value2 = Math.abs(mean - lowerLimit) / (3 * standardDeviation);

    return Math.min(value1, value2);
  });

  const rangeValue =
    props.searchValue.searchType !== 0
      ? props?.source.xlist?.map((date: any) => {
          const matchedItems = props?.ldata?.filter((item: any) => item[searchKey] === date);

          if (matchedItems.length === 0) return undefined;

          const maxUpperDistance = Math.max(
            ...matchedItems.map((item: {upper_distance: any}) => item.upper_distance)
          );
          const minUpperDistance = Math.min(
            ...matchedItems.map((item: {upper_distance: any}) => item.upper_distance)
          );

          return maxUpperDistance - minUpperDistance;
        })
      : props.ldata.map((item: any, i: number) => {
          if (i === 0) return undefined;
          return Math.abs(item.upper_distance - props.ldata[i - 1].upper_distance);
        });

  const [exportFields, setExportFields] = useState({});
  const [exportData, setExportData] = useState<any>([]);

  useEffect(() => {
    if (props.searchValue.searchType === 0) {
      //検査ごと
      setExportFields({
        シリアルナンバー: t('シリアルナンバー'),
        検査日時: t('検査日時'),
        検査部位: t('検査部位'),
        範囲: t('範囲')
      });

      const _exportData = props.source.xlist
        .map((xval: string, i: number) => {
          if (!rangeValue[i]) return null;

          return {
            シリアルナンバー: props.source.detail[i].serial || props.source.detail[i].uuid,
            検査日時: fmtYYYYMMDDHHmmss(props.source.detail[i].lq_time),
            検査部位: props.source.map,
            範囲: rangeValue[i]
          };
        })
        .filter((item: any) => item !== null); // nullの行を除外

      setExportData(_exportData);
    } else {
      setExportFields({
        検査日時: t('検査日時'),
        検査部位: t('検査部位'),
        標準偏差: t('標準偏差'),
        範囲_最大最小差: t('範囲（最大最小差）'),
        Cp: t('Cp'),
        Cpk: t('Cpk')
      });

      const _exportData = props.source.xlist
        .map((xval: string, i: number) => {
          if (!standardDeviation[i]) return null;

          return {
            検査日時: fmtYYYYMMDDHHmmss(xval),
            検査部位: props.source.map,
            標準偏差: standardDeviation[i],
            範囲_最大最小差: rangeValue[i],
            Cp: cpValue[i],
            Cpk: cpkValue[i]
          };
        })
        .filter((item: any) => item !== null); // nullの行を除外

      setExportData(_exportData);
    }
  }, [props]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          left: props.grid !== undefined ? props.grid.left : '75',
          right: props.grid !== undefined ? props.grid.right : '5',
          bottom: props.grid !== undefined ? props.grid.bottom : '20%',
          top: props.grid !== undefined ? props.grid.top : '15%'
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 11,
          textStyle: {
            fontSize: 10
          },
          icon: 'circle',
          selected: legendSelected,
          data: [t('標準偏差'), t('範囲'), 'Cp', 'Cpk'],
          formatter: (prm: any) => {
            return t(prm);
          }
        },
        tooltip: {
          trigger: 'axis',
          position: (point: any, params: any, dom: any, rect: any, size: any) => {
            return [point[0] + 20, '10%'];
          },
          formatter: (prm: any) => {
            let tmp: any;
            tmp = t('計測日時') + ':' + prm[0].axisValue + '<br/>';
            prm.forEach((a: any) => {
              if (a.value !== undefined) {
                tmp += a.marker + t(a.seriesName) + ':' + Number(a.value).toFixed(3) + '<br/>';
              } else {
                tmp += a.marker + t(a.seriesName) + ':' + t('データなし') + '<br/>';
              }
            });
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        xAxis: {
          type: 'category',
          data: props.source.xlist,
          axisLabel: {
            rotate: '15',
            fontSize: 10
          }
        },
        yAxis: [
          {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameLocation: 'end',
            position: 'left',
            axisLabel: {
              fontSize: 10
            }
          }
        ],
        series:
          props.searchValue.searchType === 0
            ? [
                {
                  name: t('範囲'),
                  data: rangeValue,
                  stack: false,
                  type: 'line',
                  showSymbol: true,
                  showAllSymbol: true,
                  color: theme.palette.primary.main
                }
              ]
            : [
                {
                  name: t('標準偏差'),
                  data: standardDeviation,
                  stack: false,
                  type: 'line',
                  showSymbol: true,
                  showAllSymbol: true,
                  color: theme.palette.primary.main
                },
                {
                  name: t('範囲'),
                  data: rangeValue,
                  stack: false,
                  type: 'line',
                  showSymbol: true,
                  showAllSymbol: true,
                  color: colorPallet[1]
                },
                {
                  name: 'Cp',
                  data: cpValue,
                  stack: false,
                  type: 'line',
                  showSymbol: true,
                  showAllSymbol: true,
                  color: colorPallet[2]
                },
                {
                  name: 'Cpk',
                  data: cpkValue,
                  stack: false,
                  type: 'line',
                  showSymbol: true,
                  showAllSymbol: true,
                  color: colorPallet[3]
                }
              ]
      }}
      exportData={exportData}
      exportFields={exportFields}
      exportFilename={props.exportFilename}
      height={props.height !== undefined ? props.height : '300px'}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick(params);
          }
        },
        legendselectchanged: (params: any) => {
          legendChange(params);
        }
      }}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomChartId === props.zoomedChartId}
    />
  );
};
export default LFCChartsBoxXYZ7;
